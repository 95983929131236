import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../auth/auth.service';
import { AreYouSureComponent } from '../../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { yesNoOptions } from '../../../../shared/formHelpers';
import { KeyValuePair } from '../../../../shared/models/key-value-pair';
import { FamilyMemberType } from '../../../../shared/models/learner';
import { FamilyRelationshipService } from '../../../../shared/services/family-relationship/family-relationship.service';
import { LearnerService } from '../../../../shared/services/learner/learner.service';
import { MemoryStorageService } from '../../../../shared/services/memory-storage/memory-storage.service';
import { SpinnerService } from '../../../../shared/services/spinner/spinner.service';
import { conditionalValidator, noNumbersValidator, phoneValidator } from '../../../../shared/validators';
import { DhhFamilyMember, DhhLearnerDto } from '../../../models/DhhDtos';
import { DhhLookupsService } from '../../../services/dhh-lookups.service';
import { DhhService } from '../../../services/dhh.service';
import { getFamilyMemberTypeFromLabel } from '../../../../shared/family-member-type-helpers';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { DeactivationService } from 'src/app/shared/services/deactivation.service';

const stateAssignedIdLengthValidator = (control: FormControl): ValidationErrors | null => {
  return control.value && control.value.toString().length !== 10 ? { stateIdInvalidLength: true } : null;
};

const bestWayToReachValidator = (control: FormGroup): ValidationErrors | null => {
  const homePhone = control.get('homePhone');
  const homePhoneIsBest = control.get('bestWayToReachHomePhone');
  const cellPhone = control.get('cellPhone');
  const cellPhoneIsBest = control.get('bestWayToReachCellPhone');
  const textIsBest = control.get('bestWayToReachText');
  const workPhone = control.get('workPhone');
  const workPhoneIsBest = control.get('bestWayToReachWorkPhone');
  const email = control.get('email');
  const emailIsBest = control.get('bestWayToReachEmail');

  if (
    (!!homePhoneIsBest.value && !homePhone.value) ||
    (!!workPhoneIsBest.value && !workPhone.value) ||
    (!!cellPhoneIsBest.value && !cellPhone.value) ||
    (!!textIsBest.value && !cellPhone.value) ||
    (!!emailIsBest.value && !email.value)
  ) {
    const errors = {};
    errors['OnePhoneNumberContactRequired'] = true;
    return errors;
  } else {
    return null;
  }
};

@Component({
  selector: 'app-dhh-learner-entry-form',
  templateUrl: './dhh-learner-entry-form.component.html',
  styleUrls: ['./dhh-learner-entry-form.component.scss'],
})
export class DhhLearnerEntryFormComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() learner: DhhLearnerDto;
  @Input() autoSave = false;
  @Input() minimalInputRequirement = false;

  @Output() completeEvent = new EventEmitter<DhhLearnerDto>();
  @Output() closeEvent = new EventEmitter();

  subscriptions = new Subscription();
  private districts: any[] = [];
  private buildings: any[] = [];

  aeaOptions: KeyValuePair[];
  districtOptions: KeyValuePair[];
  buildingOptions: KeyValuePair[];

  relationshipOptions: KeyValuePair[] = [];
  riskFactorOptions: KeyValuePair[] = [];
  gradeOptions: KeyValuePair[] = [
    new KeyValuePair('U3', 'U3'),
    new KeyValuePair('PreK', 'PK'),
    new KeyValuePair('Kindergarten', 'K'),
    new KeyValuePair('First', '1'),
    new KeyValuePair('Second', '2'),
    new KeyValuePair('Third', '3'),
    new KeyValuePair('Fourth', '4'),
    new KeyValuePair('Fifth', '5'),
    new KeyValuePair('Sixth', '6'),
    new KeyValuePair('Seventh', '7'),
    new KeyValuePair('Eighth', '8'),
    new KeyValuePair('Ninth', '9'),
    new KeyValuePair('Tenth', '10'),
    new KeyValuePair('Eleventh', '11'),
    new KeyValuePair('Twelfth', '12'),
  ];

  familyMembers: DhhFamilyMember[] = [];
  dataSource = new MatTableDataSource<DhhFamilyMember>();
  displayedColumns = ['actions', 'fullName', 'email', 'homePhone', 'familyRelationship'];

  formGroup: FormGroup;
  isReady = false;
  today = dayjs().startOf('day').toDate();
  twentyFiveYearsAgo = dayjs(this.today).subtract(25, 'year').toDate();
  pageTitle = 'New Learner Entry';
  isFamilyContactEditing = true;
  editTitle = 'Edit';
  yesNo = yesNoOptions;
  isAeaRequired = false;
  temporaryId = 1;

  get getFamilyFormGroup() {
    return this.formGroup.get('familyFormGroup') as FormGroup;
  }

  get isFormValid(): boolean {
    if (this.isFamilyContactEditing) {
      return this.formGroup.valid && this.isFamilyFormValid;
    }

    const firstName = this.formGroup.get('firstName').value;
    const lastName = this.formGroup.get('lastName').value;
    const dateOfBirth = this.formGroup.get('dateOfBirth').value;
    const grade = this.formGroup.get('grade').value;
    const attendingAeaId = this.formGroup.get('attendingAeaId').value;
    const attendingDistrictId = this.formGroup.get('attendingDistrictId').value;
    const buildingId = this.formGroup.get('buildingId').value;
    const stateAssignedInvalid = this.formGroup.get('stateAssignedId').invalid;

    return (
      firstName &&
      (lastName || this.minimalInputRequirement) &&
      (dateOfBirth || this.minimalInputRequirement) &&
      (grade || this.minimalInputRequirement) &&
      ((!this.isAeaRequired && !this.minimalInputRequirement) || attendingAeaId) &&
      (!this.minimalInputRequirement || attendingDistrictId) &&
      (!this.minimalInputRequirement || buildingId) &&
      !stateAssignedInvalid
    );
  }

  get isFamilyFormValid(): boolean {
    return (this.formGroup.get('familyFormGroup') as FormGroup)?.valid;
  }

  get canEditStateId() {
    return this.authService?.isSuperAdmin || this.authService?.isDataLead || this.authService?.isDataTechnician || !this.learner.learnerId;
  }

  private get isParent(): boolean {
    return (
      this.getFamilyFormGroup.controls.type.value === FamilyMemberType.Parent1 ||
      this.getFamilyFormGroup.controls.type.value === FamilyMemberType.Parent2
    );
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly dhhService: DhhService,
    private readonly dhhLookupService: DhhLookupsService,
    private readonly learnerService: LearnerService,
    private readonly authService: AuthService,
    private readonly memoryStorageService: MemoryStorageService,
    private readonly familyMemberRelationshipService: FamilyRelationshipService,
    private readonly spinnerService: SpinnerService,
    private readonly dialog: MatDialog,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  async ngOnInit(): Promise<void> {
    if (!this.learner) {
      this.learner = this.memoryStorageService.getKey('dhh-learner-entry') as DhhLearnerDto;
    }

    if (this.learner) {
      if (this.learner.attendingAeaId) {
        this.isAeaRequired = true;
      }

      if (this.learner.familyMembers?.length > 0 || this.minimalInputRequirement) {
        this.isFamilyContactEditing = false;

        this.familyMembers = this.learner.familyMembers ? this.learner.familyMembers : [];
        this.familyMembers?.forEach((f, index) => {
          f.temporaryId = index.toString();
        });
      }

      this.dataSource.data = this.familyMembers;
    }

    this.initializeFormGroup();

    setTimeout(() => this.spinnerService.incrementLoading(), 100);

    await this.getLookups().then(() => {
      this.filterDistricts(this.learner?.attendingAeaId);
      this.filterBuildings(this.learner?.attendingDistrictId);
      this.isReady = true;

      if (!this.canEditStateId) {
        this.formGroup.controls.stateAssignedId.disable();
        this.formGroup.controls.stateAssignedId.updateValueAndValidity();
      }

      setTimeout(() => this.spinnerService.decrementLoading());
    });

    this.subscriptions.add(
      this.formGroup.get('attendingAeaId').valueChanges.subscribe((aeaId) => {
        this.formGroup.get('attendingDistrictId').reset();
        this.filterDistricts(aeaId);
      })
    );

    this.subscriptions.add(
      this.formGroup.get('attendingDistrictId').valueChanges.subscribe((districtId) => {
        this.formGroup.get('buildingId').reset();
        this.filterBuildings(districtId);
      })
    );
  }

  async getLookups() {
    const riskFactors = await this.dhhLookupService.getRiskFactors().toPromise();
    this.riskFactorOptions = riskFactors.value.map((a) => new KeyValuePair(a.id, a.label)).sort((a, b) => a.value.localeCompare(b.value));

    const relationships = await this.familyMemberRelationshipService.get().toPromise();
    this.relationshipOptions = relationships.map((r) => new KeyValuePair(r.id, r.label, false, r.isActive));

    const removeChildren = (lookup) => {
      return {
        childRequired: lookup.childRequired,
        id: lookup.id,
        isDeleted: lookup.isDeleted,
        label: lookup.label,
        lookupName: lookup.lookupName,
        parentId: lookup.parentId,
      };
    };

    const locations = await this.dhhLookupService.getLocations().toPromise();
    const aeasLookup = locations ?? [];
    const districts = aeasLookup.flatMap((x) => x.children);
    const buildings = districts.flatMap((x) => x.children);

    const aeas = aeasLookup.map((x) => removeChildren(x)).sort((a, b) => a.label.localeCompare(b.label)) || [];
    this.districts = districts.map((x) => removeChildren(x)).sort((a, b) => a.label.localeCompare(b.label)) || [];
    this.buildings = buildings.map((x) => removeChildren(x)).sort((a, b) => a.label.localeCompare(b.label)) || [];

    this.aeaOptions = this.getHierarchicalLookup(aeas);
  }

  private getHierarchicalLookup(lookup, parentId?) {
    return lookup.filter((x) => (parentId && x.parentId === parentId) || !parentId).map((x) => new KeyValuePair(x.id, x.label));
  }

  initializeFormGroup() {
    this.formGroup = this.fb.group({
      learnerId: [null],
      firstName: [null, [Validators.required, noNumbersValidator]],
      lastName: [null, this.minimalInputRequirement ? [noNumbersValidator] : [Validators.required, noNumbersValidator]],
      dateOfBirth: [null, this.minimalInputRequirement ? null : [Validators.required]],
      grade: [null, this.minimalInputRequirement ? null : [Validators.required]],
      stateAssignedId: [
        {
          value: null,
          disabled: this.minimalInputRequirement,
        },
        this.minimalInputRequirement ? [] : [stateAssignedIdLengthValidator],
      ],
      attendingAeaId: [null, this.isAeaRequired || this.minimalInputRequirement ? Validators.required : null],
      attendingDistrictId: [null, this.minimalInputRequirement ? Validators.required : null],
      buildingId: [null, this.minimalInputRequirement ? Validators.required : null],
      familyFormGroup: this.fb.group(
        {
          temporaryId: [null],
          id: [null],
          fullName: [null, [Validators.required, noNumbersValidator]],
          type: [null],
          email: [null, Validators.email],
          livesWithChild: [null, [Validators.required]],
          streetAddress: [null],
          city: [null],
          zipCode: [null],
          state: [null],
          homePhone: [null, phoneValidator],
          workPhone: [null, phoneValidator],
          cellPhone: [null, phoneValidator],
          bestWayToReachHomePhone: [null],
          bestWayToReachCellPhone: [null],
          bestWayToReachWorkPhone: [null],
          bestWayToReachEmail: [null],
          bestWayToReachText: [null],
          bestTimeToContact: [null],
          isPersonCompletingReferral: [null],
          familyRelationshipId: [null, [conditionalValidator(() => !this.isParent, Validators.required)]],
          familyRelationship: [null],
          familyUserId: [null],
        },
        {
          validators: [bestWayToReachValidator],
        }
      ),
      birthHospital: [''],
      hadNICUCare: [''],
      numberOfDaysInNICU: [''],
      isThereHearingLossHistory: [''],
      otherNotes: [''],
      riskFactors: [''],
    });

    this.getFamilyFormGroup.controls.familyRelationshipId.valueChanges.subscribe((value) => this.familyRelationshipOnChange(value));

    if (this.learner) {
      this.formGroup.patchValue(this.learner);
    }
  }

  familyRelationshipOnChange(familyRelationshipId: string) {
    if (!familyRelationshipId) return;

    const optionName = KeyValuePair.getValue(this.relationshipOptions, familyRelationshipId);

    if (optionName === 'Parent (Biological or Adoptive)') {
      const existingParents = this.dataSource.data?.filter(
        (f) => f.type === FamilyMemberType.Parent1 || f.type === FamilyMemberType.Parent2 || f.type === FamilyMemberType.Parent
      );

      if (this.formGroup.controls.stateAssignedId.value) {
        // update type accordingly
        if (existingParents && existingParents.some((f) => f.type === FamilyMemberType.Parent1)) {
          if (existingParents.some((f) => f.type === FamilyMemberType.Parent2)) {
            this.getFamilyFormGroup.controls.type.setValue(FamilyMemberType.Parent);
          } else {
            this.getFamilyFormGroup.controls.type.setValue(FamilyMemberType.Parent2);
          }
        } else {
          this.getFamilyFormGroup.controls.type.setValue(FamilyMemberType.Parent1);
        }
      } else {
        this.getFamilyFormGroup.controls.type.setValue(FamilyMemberType.ParentTemporary);
        this.getFamilyFormGroup.controls.familyRelationship.setValue(FamilyMemberType.ParentTemporary);
      }
    } else {
      this.getFamilyFormGroup.controls.type.setValue(optionName);
      this.getFamilyFormGroup.controls.familyRelationship.setValue(optionName);
    }

    return;
  }

  filterDistricts(aeaId?: string) {
    if (aeaId) {
      this.districtOptions = this.getHierarchicalLookup(this.districts, aeaId);
    } else {
      this.districtOptions = this.getHierarchicalLookup(this.districts);
    }
  }

  filterBuildings(districtId?: string) {
    if (districtId) {
      this.buildingOptions = this.getHierarchicalLookup(this.buildings, districtId);
    } else {
      this.buildingOptions = this.getHierarchicalLookup(this.buildings);
    }
  }

  addingNewFamilyMember() {
    this.isFamilyContactEditing = true;
    this.temporaryId++;
    this.getFamilyFormGroup.controls.temporaryId.setValue(this.temporaryId++);
  }

  editFamilyMember(familyMember: DhhFamilyMember) {
    this.isFamilyContactEditing = true;
    this.getFamilyFormGroup.patchValue(familyMember);
  }

  deleteFamilyMember(familyMember: DhhFamilyMember) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Clicking Yes will remove this family member.',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        if (this.autoSave) {
          this.learnerService.deleteFamilyMember(this.learner.learnerId, familyMember.id).subscribe(
            () => {
              this.familyMembers = this.familyMembers.filter((x) => x.id !== familyMember.id);
              this.dataSource.data = this.familyMembers;
            },
            (error) => {
              this.dhhService.handleError('There was an error while deleting family member.', error);
            }
          );
        } else {
          this.familyMembers = this.familyMembers.filter((x) => x.temporaryId !== familyMember.temporaryId);
          this.dataSource.data = this.familyMembers;
        }
      }
    });
  }

  cancelEditFamilyMember() {
    this.isFamilyContactEditing = false;
    this.temporaryId--;
    this.getFamilyFormGroup.reset();
  }

  saveFamilyMember() {
    if (this.getFamilyFormGroup.invalid) {
      this.getFamilyFormGroup.markAllAsTouched();
      return;
    }

    if (!this.familyMembers) {
      this.familyMembers = [];
    }

    const tempId = this.getFamilyFormGroup.controls.temporaryId.value;

    let familyMember = this.familyMembers.find((f) => f.temporaryId === tempId);

    let familyRelationshipId = this.getFamilyFormGroup.controls.familyRelationshipId.value;

    const optionName = KeyValuePair.getValue(this.relationshipOptions, familyRelationshipId);

    if (optionName === 'Parent (Biological or Adoptive)' && !this.formGroup.controls.stateAssignedId.value) {
      familyRelationshipId = this.relationshipOptions.find((r) => r.value === 'Parent Temporary')?.key;
    }

    if (familyMember) {
      familyMember.id = this.getFamilyFormGroup.controls.id.value;
      familyMember.fullName = this.getFamilyFormGroup.controls.fullName.value;
      familyMember.email = this.getFamilyFormGroup.controls.email.value;
      familyMember.type = this.getFamilyFormGroup.controls.type.value;
      familyMember.livesWithChild = this.getFamilyFormGroup.controls.livesWithChild.value;
      familyMember.homePhone = this.getFamilyFormGroup.controls.homePhone.value;
      familyMember.workPhone = this.getFamilyFormGroup.controls.workPhone.value;
      familyMember.cellPhone = this.getFamilyFormGroup.controls.cellPhone.value;
      familyMember.bestWayToReachHomePhone = this.getFamilyFormGroup.controls.bestWayToReachHomePhone.value;
      familyMember.bestWayToReachCellPhone = this.getFamilyFormGroup.controls.bestWayToReachCellPhone.value;
      familyMember.bestWayToReachWorkPhone = this.getFamilyFormGroup.controls.bestWayToReachWorkPhone.value;
      familyMember.bestWayToReachEmail = this.getFamilyFormGroup.controls.bestWayToReachEmail.value;
      familyMember.bestWayToReachText = this.getFamilyFormGroup.controls.bestWayToReachText.value;
      familyMember.bestTimeToContact = this.getFamilyFormGroup.controls.bestTimeToContact.value;
      familyMember.streetAddress = this.getFamilyFormGroup.controls.streetAddress.value;
      familyMember.city = this.getFamilyFormGroup.controls.city.value;
      familyMember.state = this.getFamilyFormGroup.controls.state.value;
      familyMember.zipCode = this.getFamilyFormGroup.controls.zipCode.value;
      familyMember.familyRelationshipId = familyRelationshipId;
      familyMember.familyRelationship = this.getRelationshipName(familyRelationshipId);
      familyMember.type = getFamilyMemberTypeFromLabel(familyMember.type) as FamilyMemberType;

      const handleUpdateCallback = () => {
        this.isFamilyContactEditing = false;
        this.getFamilyFormGroup.reset();
      };

      if (this.autoSave) {
        if (this.getFamilyFormGroup.controls.id.value) {
          this.learnerService.updateFamilyMember(this.learner.learnerId, familyMember).subscribe(
            () => {
              handleUpdateCallback();
            },
            (error) => {
              this.dhhService.handleError('There was an error while saving family member.', error);
            }
          );
        }
      } else {
        handleUpdateCallback();
      }
    } else {
      familyMember = {
        temporaryId: this.getFamilyFormGroup.controls.temporaryId.value,
        id: this.getFamilyFormGroup.controls.id.value,
        fullName: this.getFamilyFormGroup.controls.fullName.value,
        email: this.getFamilyFormGroup.controls.email.value,
        type: this.getFamilyFormGroup.controls.type.value,
        livesWithChild: this.getFamilyFormGroup.controls.livesWithChild.value,
        homePhone: this.getFamilyFormGroup.controls.homePhone.value,
        workPhone: this.getFamilyFormGroup.controls.workPhone.value,
        cellPhone: this.getFamilyFormGroup.controls.cellPhone.value,
        bestWayToReachHomePhone: this.getFamilyFormGroup.controls.bestWayToReachHomePhone.value,
        bestWayToReachCellPhone: this.getFamilyFormGroup.controls.bestWayToReachCellPhone.value,
        bestWayToReachWorkPhone: this.getFamilyFormGroup.controls.bestWayToReachWorkPhone.value,
        bestWayToReachEmail: this.getFamilyFormGroup.controls.bestWayToReachEmail.value,
        bestWayToReachText: this.getFamilyFormGroup.controls.bestWayToReachText.value,
        bestTimeToContact: this.getFamilyFormGroup.controls.bestTimeToContact.value,
        streetAddress: this.getFamilyFormGroup.controls.streetAddress.value,
        city: this.getFamilyFormGroup.controls.city.value,
        state: this.getFamilyFormGroup.controls.state.value,
        zipCode: this.getFamilyFormGroup.controls.zipCode.value,
        familyRelationshipId: familyRelationshipId,
        familyRelationship: this.getRelationshipName(familyRelationshipId),
      } as DhhFamilyMember;
      familyMember.type = getFamilyMemberTypeFromLabel(familyMember.type) as FamilyMemberType;

      const handleAddCallback = () => {
        this.familyMembers.push(familyMember);
        this.dataSource.data = this.familyMembers;
        this.isFamilyContactEditing = false;
        this.getFamilyFormGroup.reset();
      };

      if (this.autoSave) {
        this.learnerService.addFamilyMember(this.learner.learnerId, familyMember).subscribe(
          (result) => {
            familyMember.id = result.id;
            handleAddCallback();
          },
          (error) => {
            this.dhhService.handleError('There was an error while saving family member.', error);
          }
        );
      } else {
        handleAddCallback();
      }
    }
  }

  getRelationshipName(familyRelationshipId: string) {
    return KeyValuePair.getValue(this.relationshipOptions, familyRelationshipId);
  }

  onSubmit() {
    this.mapFormDataToModel();

    //if auto saved just emit the complete event, otherwise send the form data for the wrapper component
    if (this.autoSave) {
      this.dhhService.updateLearner(this.learner).subscribe(
        (result) => {
          if (result.succeeded) {
            this.completeEvent.emit(null);
          } else {
            this.dhhService.handleError('Failed to update learner record.', result);
          }
        },
        (error) => {
          this.dhhService.handleError('Failed to update learner record.', error);
        }
      );
    } else {
      this.completeEvent.emit(this.learner);
    }
  }

  private mapFormDataToModel() {
    if (!this.learner) {
      this.learner = {} as DhhLearnerDto;
    }

    this.learner.firstName = this.formGroup.controls.firstName.value;
    this.learner.lastName = this.formGroup.controls.lastName.value;
    this.learner.dateOfBirth = this.formGroup.controls.dateOfBirth.value;
    this.learner.grade = this.formGroup.controls.grade.value;
    this.learner.stateAssignedId = this.formGroup.controls.stateAssignedId.value;
    this.learner.attendingAeaId = this.formGroup.controls.attendingAeaId.value;
    this.learner.attendingDistrictId = this.formGroup.controls.attendingDistrictId.value;
    this.learner.buildingId = this.formGroup.controls.buildingId.value;
    this.learner.birthHospital = this.formGroup.controls.birthHospital.value;
    this.learner.hadNICUCare = this.formGroup.controls.hadNICUCare.value;
    this.learner.numberOfDaysInNICU = this.formGroup.controls.numberOfDaysInNICU.value;
    this.learner.isThereHearingLossHistory = this.formGroup.controls.isThereHearingLossHistory.value;
    this.learner.otherNotes = this.formGroup.controls.otherNotes.value;
    this.learner.riskFactors = this.formGroup.controls.riskFactors.value;

    this.familyMembers?.forEach((f) => {
      f.type = getFamilyMemberTypeFromLabel(f.type) as FamilyMemberType;
    });

    this.learner.familyMembers = this.familyMembers;
  }

  onClose() {
    this.closeEvent.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }
}
