import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ReferralSourceCategory } from 'src/app/child-find/early-access-referral/referral-source-category';
import { District } from 'src/app/shared/models/district';
import { FamilyMemberType, LearnerCreate } from 'src/app/shared/models/learner';
import { DistrictService } from 'src/app/shared/services/district/district.service';
import { GenderService } from 'src/app/shared/services/gender/gender.service';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { LocationService } from 'src/app/shared/services/location/location.service';
import * as AppValidators from 'src/app/shared/validators';
import { ReferralService } from '../../../child-find/early-access-referral/early-access-referral.service';
import { Referral } from '../../../child-find/early-access-referral/referral';
import {
  AddConsentDialogResult,
  FamilyConsentAddComponent,
} from '../../../consent/family-consent/modals/family-consent-add/family-consent-add.component';
import { usStateOptions, yesNoOptions } from '../../formHelpers';
import { Building } from '../../models/building';
import { IntakeType } from '../../models/case';
import { ConsentForm, consentFormTitlesByType, ConsentFormType } from '../../models/fiie-consent/consent-form';
import { FileDocument } from '../../models/file-document';
import { KeyValuePair } from '../../models/key-value-pair';
import { UnknownYesNo } from '../../models/yes-no';
import { DateToUtcPipe, StringDateTimeToDateTimePipe } from '../../pipes/date-transform.pipe';
import { AchieveConfigService } from '../../services/achieve-config-service/achieve-config.service';
import { BuildingService } from '../../services/building/building.service';
import { ConsentFormService } from '../../services/consent-form/consent-form.service';
import { FamilyRelationshipService } from '../../services/family-relationship/family-relationship.service';
import { MemoryStorageService } from '../../services/memory-storage/memory-storage.service';
import { AlertDialogComponent, NotificationService } from '../../services/notification.service';
import { RoutingService } from '../../services/routing.service';
import { AreYouSureComponent } from '../are-you-sure-modal/are-you-sure.component';
import { FamilyRelationship } from '../../models/family-relationship';

@Component({
  selector: 'app-new-child',
  templateUrl: './new-child.component.html',
  styleUrls: ['./new-child.component.scss'],
  providers: [StringDateTimeToDateTimePipe],
})
export class NewChildComponent implements OnInit, OnDestroy {
  private subscriptions = new Array<Subscription>();
  activeCall = false;

  referralId: string;
  referral: Referral;

  genderOptions: KeyValuePair[];
  languageOptions: KeyValuePair[];
  referralSourceCategories: ReferralSourceCategory[];
  referralHowHearAboutUsOptions: KeyValuePair[];
  howHearAboutUsOtherId: string;
  relationships: FamilyRelationship[] = [];
  relationshipOptions: KeyValuePair[];
  yesNoOptions = yesNoOptions;
  usStateOptions = usStateOptions;
  districts: District[];
  districtOptions: KeyValuePair[];
  buildingOptions: KeyValuePair[];
  referralSourceCategoryOptions: KeyValuePair[];
  routeInfo: any;
  screeningToolOptions: KeyValuePair[];
  screeningToolOtherId: string;

  // TODO: move this to AddOrUpdateLearnerDto/formgroup
  consentDocuments: ConsentForm[] = [];
  validBirthday: boolean;
  today = dayjs().startOf('day').toDate();
  familyMemberType = FamilyMemberType;
  submitAttempted = false;
  pageLoaded = false;
  availableConsentTypes = [ConsentFormType.ReceiveElectronicCommunication, ConsentFormType.ReleaseAndExchangeInformation];
  availConsentFormTypeOptions: KeyValuePair[] = [];
  isFromChildSearch = false;

  guidRegExp = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/g;

  formGroup = this.fb.group(
    {
      firstName: [null, [Validators.required, AppValidators.noNumbersValidator]],
      middleName: [null, [AppValidators.noNumbersValidator]],
      lastName: [null, [Validators.required, AppValidators.noNumbersValidator]],
      dateOfBirth: [null, { Validators: [Validators.required], updateOn: 'blur' }],
      genderId: [null],
      languageSpokenInHomeId: [null, Validators.required],
      interpreterNeeded: [null, Validators.required],
      streetAddress: [null, Validators.required],
      zipCode: [null, [Validators.required, AppValidators.zipCodeValidator]],
      city: [null, [Validators.required, AppValidators.noNumbersLimitedSpecialCharsValidator]],
      county: [null, AppValidators.noNumbersLimitedSpecialCharsValidator],
      state: [null],
      residentAeaId: [null],
      residentDistrictId: [null, Validators.required],
      attendingDistrictId: [null, Validators.required],
      attendingBuildingId: [null],
      movingFromOutOfState: [null],
      eligibleInLegacySystem: [null],
      eligibleInLegacySystemPartC: [null],
      referralId: [null],
      attendsChildCare: [null],
      childCareProviderName: [null],
      childCareProviderStreetAddress: [null],
      childCareProviderZipCode: [null, AppValidators.zipCodeValidator],
      childCareProviderCity: [null, AppValidators.noNumbersValidator],
      childCareProviderState: [null],
      othersInHome: [null],
      childsHome: [null],
      otherFamilyHome: [null],
      childcareCenter: [null],
      headStart: [null],
      preschool: [null],
      playgroup: [null],
      otherEnvironment: [null],
      referralSource: [null],
      referralSourceHowHearAboutUsId: [null],
      referralSourceEarlyAccessOtherText: [null],
      legalGuardian: [null],
      exchangeInformation: [null],
      parent1: this.fb.group(
        {
          fullName: [null, [Validators.required, AppValidators.noNumbersValidator]],
          type: [FamilyMemberType.Parent1],
          email: [null, Validators.email],
          livesWithChild: [null, Validators.required],
          streetAddress: [null],
          city: [null],
          zipCode: [null],
          state: [null],
          homePhone: [null, AppValidators.phoneValidator],
          workPhone: [null, AppValidators.phoneValidator],
          cellPhone: [null, AppValidators.phoneValidator],
          bestWayToReachHomePhone: [null],
          bestWayToReachCellPhone: [null],
          bestWayToReachWorkPhone: [null],
          bestWayToReachEmail: [null],
          bestWayToReachText: [null],
          bestTimeToContact: [null],
          isPersonCompletingReferral: [null],
          familyRelationshipId: [null],
        },
        {
          validators: [familyMemberNameValidator, bestWayToReachValidator('parent1')],
        }
      ),
      parent2: this.fb.group(
        {
          fullName: [null, AppValidators.noNumbersValidator],
          type: [FamilyMemberType.Parent2],
          email: [null, Validators.email],
          livesWithChild: [null],
          streetAddress: [null],
          city: [null],
          zipCode: [null],
          state: [null],
          homePhone: [null, AppValidators.phoneValidator],
          workPhone: [null, AppValidators.phoneValidator],
          cellPhone: [null, AppValidators.phoneValidator],
          bestWayToReachHomePhone: [null],
          bestWayToReachCellPhone: [null],
          bestWayToReachWorkPhone: [null],
          bestWayToReachEmail: [null],
          bestWayToReachText: [null],
          bestTimeToContact: [null],
          isPersonCompletingReferral: [null],
          familyRelationshipId: [null],
        },
        {
          validators: [livesWithValidator, bestWayToReachValidator('parent2')],
        }
      ),
      referralSourceInfo: this.fb.group({
        referralSourceCategoryId: null,
        referralSourceName: [null, [AppValidators.noNumbersValidator]],
        referralSourceEmail: [null, [Validators.email]],
        referralSourceAgency: [null, [this.agencyValidator.bind(this)]],
        referralSourceHomePhone: [null, AppValidators.phoneValidator],
        referralSourceWorkPhone: [null, [AppValidators.phoneValidator]],
        referralSourceCellPhone: [null, [AppValidators.phoneValidator]],
        referralSourceAddress: [null],
        referralSourceCity: [null, [AppValidators.noNumbersValidator]],
        referralSourceState: [null, [AppValidators.noNumbersValidator]],
        referralSourceZipCode: [null, [AppValidators.zipCodeValidator]],
      }),
    },
    {
      validators: [guardianValidator, exchangeValidator, atLeastOnePhoneValidator],
    }
  );

  get parent1() {
    return this.formGroup.controls.parent1 as FormGroup;
  }

  get parent2() {
    return this.formGroup.controls.parent2 as FormGroup;
  }

  get livesWith1() {
    return this.formGroup.controls.livesWith1 as FormGroup;
  }

  get livesWith2() {
    return this.formGroup.controls.livesWith2 as FormGroup;
  }

  get familyMembers() {
    const familyMembers = [this.parent1, this.parent2];
    if (!!this.livesWith1) {
      familyMembers.push(this.livesWith1);
    }
    if (!!this.livesWith2) {
      familyMembers.push(this.livesWith2);
    }
    return familyMembers;
  }

  get atLeastOneParentLivesWith() {
    return !!this.parent1.get('livesWithChild').value || !!this.parent2.get('livesWithChild').value;
  }

  get isThreeToFive() {
    const dob = this.formGroup.controls.dateOfBirth as FormControl;
    return isThreeToFive(dob);
  }

  get isSixOrOlder() {
    const dob = this.formGroup.controls.dateOfBirth as FormControl;
    return !!dob.value && LearnerService.isAtLeast(6, dob.value);
  }

  get referralSource() {
    return this.formGroup.get('referralSource') as FormControl;
  }

  get referralSourceInfo() {
    return this.formGroup.get('referralSourceInfo') as FormGroup;
  }

  get referralReasonInfo() {
    return this.formGroup.get('referralReasonInfo') as FormGroup;
  }

  get disableMovingFromOutOfState() {
    return this.formGroup.get('eligibleInLegacySystem').value === true;
  }

  get disableEligibleInLegacySystem() {
    return this.formGroup.get('movingFromOutOfState').value === true;
  }

  get eligibleInLegacySystemPartC() {
    return this.formGroup.get('eligibleInLegacySystemPartC').value === true;
  }

  constructor(
    private readonly districtService: DistrictService,
    private readonly fb: FormBuilder,
    private readonly genderService: GenderService,
    private readonly languageService: LanguageService,
    private readonly learnerService: LearnerService,
    private readonly locationService: LocationService,
    private readonly referralService: ReferralService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly memoryStorageService: MemoryStorageService,
    private readonly familyMemberRelationshipService: FamilyRelationshipService,
    private readonly consentFormService: ConsentFormService,
    private readonly notificationService: NotificationService,
    private readonly dialog: MatDialog,
    private readonly routingService: RoutingService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly achieveConfigService: AchieveConfigService,
    private readonly buildingService: BuildingService,
    private readonly stringDateTimeToDateTimePipe: StringDateTimeToDateTimePipe
  ) {}

  ngOnInit() {
    const childSearch = this.memoryStorageService.getKey('child-search');
    this.route.data.subscribe((data) => {
      if (!!data && data?.isNewChild) {
        this.isFromChildSearch = true;
      }
    });

    if (childSearch) {
      this.formGroup.patchValue(childSearch);
    }
    this.referralId = this.route.snapshot.paramMap.get('referralId');

    this.referralService.getScreeningTools().subscribe((res) => {
      this.screeningToolOptions = res.map((x) => new KeyValuePair(x.id, x.label));
      this.screeningToolOtherId = res.find((x) => x.isOther).id;
    });

    this.formGroup.get('eligibleInLegacySystemPartC').valueChanges.subscribe((val) => {
      if (val) {
        this.referralReasonInfo.clearValidators();
        this.referralReasonInfo.updateValueAndValidity();
      } else {
        this.setReferralReasonFormGroup();
      }
    });

    this.availConsentFormTypeOptions = this.availableConsentTypes.map(
      (c) => new KeyValuePair({ value: c, caseId: null }, consentFormTitlesByType[c])
    );
    forkJoin([
      this.languageService.getLanguages(),
      this.genderService.get(),
      this.districtService.getDistricts(),
      this.familyMemberRelationshipService.get(),
      this.referralService.getReferralSourceHowHearAboutUs(),
      this.referralService.getReferralSourceCategories(),
    ]).subscribe(([languages, genders, districts, relationships, referralHearAboutUs, referralSourceCategories]) => {
      this.languageOptions = languages.map((l) => new KeyValuePair(l.id, l.label));
      this.genderOptions = genders.map((g) => new KeyValuePair(g.id, g.label));
      this.districtOptions = districts.map((d) => new KeyValuePair(d.id, d.name));
      this.relationships = relationships;
      this.relationshipOptions = relationships.map((r) => new KeyValuePair(r.id, r.label));
      this.referralHowHearAboutUsOptions = referralHearAboutUs.map((h) => new KeyValuePair(h.id, h.label));
      this.howHearAboutUsOtherId = referralHearAboutUs.find((o) => o.isOther).id;
      this.referralSourceCategories = referralSourceCategories;
      this.referralSourceCategoryOptions = referralSourceCategories.map((c) => new KeyValuePair(c.id, c.label));

      this.setReferralSourceFormGroup();
      this.setReferralReasonFormGroup();
      this.setLivesWithFormGroups();

      if (this.referralId) {
        this.prepopulateFromReferral(this.referralId);
      }

      this.subscriptions.push(
        this.formGroup.get('parent1.livesWithChild').valueChanges.subscribe(() => {
          this.handleParentLivesWithChanges();
          this.changeDetectorRef.detectChanges();
        }),
        this.formGroup.get('parent2.livesWithChild').valueChanges.subscribe(() => {
          this.handleParentLivesWithChanges();
          this.changeDetectorRef.detectChanges();
        }),
        this.formGroup.get('referralSource').valueChanges.subscribe(() => {
          this.handleReferralSourceChanges();
          this.changeDetectorRef.detectChanges();
        }),
        this.formGroup
          .get('attendingDistrictId')
          .valueChanges.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
          .subscribe(() => {
            this.handleAttendingDistrictIdChanges();
            this.changeDetectorRef.detectChanges();
          })
      );
    });

    this.formGroup
      .get('dateOfBirth')
      .valueChanges.pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe(() => {
        this.birthdayChange();
      });

    setTimeout(() => {
      this.pageLoaded = true;
      if (this.formGroup.get('dateOfBirth').value) {
        this.validBirthday = true;
      }
    }, 0);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  handleParentLivesWithChanges() {
    const parent1LivesWithChild = this.formGroup.get('parent1.livesWithChild').value;
    const parent2LivesWithChild = this.formGroup.get('parent2.livesWithChild').value;

    if (parent1LivesWithChild === false && !parent2LivesWithChild) {
      this.livesWith1?.enable();
      this.livesWith2?.enable();
    } else {
      this.livesWith1?.disable();
      this.livesWith2?.disable();
    }
    this.formGroup.updateValueAndValidity();
  }

  setLivesWithFormGroups() {
    if (!this.formGroup.get('livesWith1')) {
      this.formGroup.setControl(
        'livesWith1',
        this.fb.group(
          {
            id: [null],
            fullName: [null, [Validators.required, AppValidators.noNumbersValidator]],
            type: [FamilyMemberType.LivesWith1],
            familyRelationshipId: [null],
            email: [null, Validators.email],
            livesWithChild: [true],
            homePhone: [null, AppValidators.phoneValidator],
            workPhone: [null, AppValidators.phoneValidator],
            cellPhone: [null, AppValidators.phoneValidator],
            bestWayToReachHomePhone: [null],
            bestWayToReachCellPhone: [null],
            bestWayToReachWorkPhone: [null],
            bestWayToReachEmail: [null],
            bestWayToReachText: [null],
            bestTimeToContact: [null],
            isPersonCompletingReferral: [null],
          },
          {
            validators: [bestWayToReachValidator('livesWith1')],
          }
        )
      );
    }
    if (!this.formGroup.get('livesWith2')) {
      this.formGroup.setControl(
        'livesWith2',
        this.fb.group(
          {
            id: [null],
            fullName: [null, AppValidators.noNumbersValidator],
            type: [FamilyMemberType.LivesWith2],
            familyRelationshipId: [null],
            email: [null, Validators.email],
            livesWithChild: [true],
            homePhone: [null, AppValidators.phoneValidator],
            workPhone: [null, AppValidators.phoneValidator],
            cellPhone: [null, AppValidators.phoneValidator],
            bestWayToReachHomePhone: [null],
            bestWayToReachCellPhone: [null],
            bestWayToReachWorkPhone: [null],
            bestWayToReachEmail: [null],
            bestWayToReachText: [null],
            bestTimeToContact: [null],
            isPersonCompletingReferral: [null],
          },
          {
            validators: [bestWayToReachValidator('livesWith2')],
          }
        )
      );
    }
    this.formGroup.updateValueAndValidity();
  }

  handleAttendingDistrictIdChanges() {
    const attendingDistrictId = this.formGroup.get('attendingDistrictId').value;
    console.log(attendingDistrictId);
    if (attendingDistrictId && this.guidRegExp.test(attendingDistrictId)) {
      this.buildingService.getBuildingsForDistrict(attendingDistrictId).subscribe((response: Building[]) => {
        const newBuildingOptions = response.map((x) => new KeyValuePair(x.id, x.name));
        this.buildingOptions = newBuildingOptions;
      });
    }
  }

  handleReferralSourceChanges() {
    if (!this.referralSourceInfo) {
      return;
    }

    if (!this.formGroup.get('referralSource').value) {
      this.referralSourceInfo.enable();
    } else {
      this.referralSourceInfo.disable();
    }

    this.referralSourceInfo.updateValueAndValidity();
  }

  setReferralSourceFormGroup() {
    if (this.isThreeToFive) {
      this.referralSourceInfo.clearValidators();
      return;
    }
    this.referralSourceInfo.setValidators([this.agencyValidator.bind(this), referralSourcePhoneValidator, referralSourceAddressValidator]);
  }

  setReferralReasonFormGroup() {
    if (this.isThreeToFive) {
      return;
    }
    this.formGroup.setControl(
      'referralReasonInfo',
      this.fb.group(
        {
          referralReasonCognitive: null,
          referralReasonMotor: null,
          referralReasonCommunication: null,
          referralReasonAdaptive: null,
          referralReasonSocialEmotional: null,
          referralReasonOther: null,
          referralReasonDesc: null,
          referralReasonScreeningToolUsed: null,
          screeningToolIds: [[]],
          screeningToolOther: null,
          referralReasonDiagnosedCondition: null,
          referralReasonDiagnosedConditions: null,
          referralReasonNameMedicallyDiagnosedCondition: null,
          referralReasonICDCode: null,
          referralReasonBornLessThan32Week: null,
          referralReasonBornInWeeks: [null, [Validators.max(31)]],
          referralReasonBornInDays: [null, [Validators.max(6)]],
          referralReasonBirthWeightLessThan3lb: null,
          referralReasonBirthWeightUnits: null,
          referralReasonBirthInLb: [null, [Validators.max(3)]],
          referralReasonBirthInOz: [null, [Validators.max(15)]],
          referralReasonBirthInGrams: null,
          referralReasonBirthInKilograms: null,
          referralReasonAbuseOrNeglect: null,
          referralReasonPIDs: null,
          referralReasonNonCAPTA: null,
        },
        {
          validators: [
            developmentValidator,
            birthPrematureValidator,
            birthWeightValidator,
            this.screeningToolValidator.bind(this),
            this.toolNameValidator.bind(this),
            diagnosedConditionValidator,
            descriptionValidator,
          ],
        }
      )
    );
    this.changeDetectorRef.detectChanges();
  }

  prepopulateFromReferral(referralId) {
    this.referralService.getReferral(referralId).subscribe(
      (referral) => {
        let childDateOfBirth = null;
        if (referral.childInfo.dateOfBirth) {
          childDateOfBirth = this.stringDateTimeToDateTimePipe.transform(referral.childInfo.dateOfBirth);
        }

        this.referral = referral;
        this.formGroup.patchValue({
          firstName: referral.childInfo.firstName,
          middleName: referral.childInfo.middleName,
          lastName: referral.childInfo.lastName,
          dateOfBirth: childDateOfBirth,
          genderId: referral.childInfo.genderId,
          languageSpokenInHomeId: referral.childInfo.languageSpokenInHomeId,
          interpreterNeeded: referral.childInfo.interpreterNeeded,
          city: referral.childInfo.city,
          state: referral.childInfo.state,
          zipCode: referral.childInfo.zipCode,
          streetAddress: referral.childInfo.streetAddress,
          county: referral.childInfo.county,

          residentAeaId: referral.childInfo.aeaId,
          residentDistrictId: referral.childInfo.childResidentDistrictId,
          attendingDistrictId: referral.childInfo.childAttendingDistrictId,

          referralId: referral.id,
          referralSource: referral.referralSource,
          attendsChildCare: referral.childInfo.attendsChildCare,
          childCareProviderName: referral.childInfo.childCareProviderName,
          childCareProviderStreetAddress: referral.childInfo.childCareProviderStreetAddress,
          childCareProviderCity: referral.childInfo.childCareProviderCity,
          childCareProviderState: referral.childInfo.childCareProviderState,
          childCareProviderZipCode: referral.childInfo.childCareProviderZipCode,
          othersInHome: referral.parentInfo.othersInHome,
          referralSourceHowHearAboutUsId: referral.referralSourceHowHearAboutUsId,
          referralSourceEarlyAccessOtherText: referral.referralSourceEarlyAccessOtherText,
          legalGuardian: referral.legalGuardian,
          exchangeInformation: referral.exchangeInformation,

          parent1: {
            fullName: referral.parentInfo.parent1Name,
            email: referral.parentInfo.parent1Email,
            streetAddress: referral.parentInfo.parent1StreetAddress,
            city: referral.parentInfo.parent1City,
            state: referral.parentInfo.parent1State,
            zipCode: referral.parentInfo.parent1ZipCode,
            workPhone: referral.parentInfo.parent1WorkPhone,
            cellPhone: referral.parentInfo.parent1CellPhone,
            homePhone: referral.parentInfo.parent1HomePhone,
            livesWithChild: referral.parentInfo.parent1LivesWithChild,
            bestWayToReachCellPhone: referral.parentInfo.parent1BestWayToReachCellPhone,
            bestWayToReachHomePhone: referral.parentInfo.parent1BestWayToReachHomePhone,
            bestWayToReachWorkPhone: referral.parentInfo.parent1BestWayToReachWorkPhone,
            bestWayToReachEmail: referral.parentInfo.parent1BestWayToReachEmail,
            bestWayToReachText: referral.parentInfo.parent1BestWayToReachText,
            bestTimeToContact: referral.parentInfo.parent1BestTimeToContact,
            isPersonCompletingReferral: referral.referralSource === FamilyMemberType.Parent1,
          },
          parent2: {
            fullName: referral.parentInfo.parent2Name,
            email: referral.parentInfo.parent2Email,
            streetAddress: referral.parentInfo.parent2StreetAddress,
            city: referral.parentInfo.parent2City,
            state: referral.parentInfo.parent2State,
            zipCode: referral.parentInfo.parent2ZipCode,
            workPhone: referral.parentInfo.parent2WorkPhone,
            cellPhone: referral.parentInfo.parent2CellPhone,
            homePhone: referral.parentInfo.parent2HomePhone,
            livesWithChild: referral.parentInfo.parent2LivesWithChild,
            bestWayToReachCellPhone: referral.parentInfo.parent2BestWayToReachCellPhone,
            bestWayToReachHomePhone: referral.parentInfo.parent2BestWayToReachHomePhone,
            bestWayToReachWorkPhone: referral.parentInfo.parent2BestWayToReachWorkPhone,
            bestWayToReachEmail: referral.parentInfo.parent2BestWayToReachEmail,
            bestWayToReachText: referral.parentInfo.parent2BestWayToReachText,
            bestTimeToContact: referral.parentInfo.parent2BestTimeToContact,
            isPersonCompletingReferral: referral.referralSource === FamilyMemberType.Parent2,
          },

          referralSourceInfo: {
            referralSourceCategoryId: referral.referralSourceInfo.referralSourceCategoryId,
            referralSourceName: referral.referralSourceInfo.referralSourceName,
            referralSourceEmail: referral.referralSourceInfo.referralSourceEmail,
            referralSourceAgency: referral.referralSourceInfo.referralSourceAgency,
            referralSourceHomePhone: referral.referralSourceInfo.referralSourceHomePhone,
            referralSourceWorkPhone: referral.referralSourceInfo.referralSourceWorkPhone,
            referralSourceCellPhone: referral.referralSourceInfo.referralSourceCellPhone,
            referralSourceAddress: referral.referralSourceInfo.referralSourceAddress,
            referralSourceCity: referral.referralSourceInfo.referralSourceCity,
            referralSourceState: referral.referralSourceInfo.referralSourceState,
            referralSourceZipCode: referral.referralSourceInfo.referralSourceZipCode,
          },

          referralReasonInfo: {
            referralReasonCognitive: referral.referralReasonInfo.referralReasonCognitive,
            referralReasonMotor: referral.referralReasonInfo.referralReasonMotor,
            referralReasonCommunication: referral.referralReasonInfo.referralReasonCommunication,
            referralReasonAdaptive: referral.referralReasonInfo.referralReasonAdaptive,
            referralReasonSocialEmotional: referral.referralReasonInfo.referralReasonSocialEmotional,
            referralReasonOther: referral.referralReasonInfo.referralReasonOther,
            referralReasonDesc: referral.referralReasonInfo.referralReasonDesc,
            referralReasonScreeningToolUsed: referral.referralReasonInfo.referralReasonScreeningToolUsed,
            screeningToolIds: referral.referralReasonInfo.screeningTools.map((st) => st.id),
            screeningToolOther: referral.referralReasonInfo.screeningToolOther,
            referralReasonDiagnosedCondition: referral.referralReasonInfo.referralReasonDiagnosedCondition,
            referralReasonDiagnosedConditions: referral.referralReasonInfo.referralReasonDiagnosedConditions,
            referralReasonNameMedicallyDiagnosedCondition: referral.referralReasonInfo.referralReasonNameMedicallyDiagnosedCondition,
            referralReasonICDCode: referral.referralReasonInfo.referralReasonICDCode,
            referralReasonBornLessThan32Week: referral.referralReasonInfo.referralReasonBornLessThan32Week,
            referralReasonBornInWeeks: referral.referralReasonInfo.referralReasonBornInWeeks,
            referralReasonBornInDays: referral.referralReasonInfo.referralReasonBornInDays,
            referralReasonBirthWeightLessThan3lb: referral.referralReasonInfo.referralReasonBirthWeightLessThan3lb,
            referralReasonBirthWeightUnits: referral.referralReasonInfo.referralReasonBirthWeightUnits,
            referralReasonBirthInLb: referral.referralReasonInfo.referralReasonBirthInLb,
            referralReasonBirthInOz: referral.referralReasonInfo.referralReasonBirthInOz,
            referralReasonBirthInGrams: referral.referralReasonInfo.referralReasonBirthInGrams,
            referralReasonBirthInKilograms: referral.referralReasonInfo.referralReasonBirthInKilograms,
            referralReasonAbuseOrNeglect: referral.referralReasonInfo.referralReasonAbuseOrNeglect,
            referralReasonPIDs: referral.referralReasonInfo.referralReasonPIDs,
            referralReasonNonCAPTA: referral.referralReasonInfo.referralReasonNonCAPTA,
          },
        });

        if (!!referral.familyInfo?.livesWith1Name || !!referral.familyInfo?.livesWith2Name) {
          this.setLivesWithFormGroups();
          this.formGroup.patchValue({
            livesWith1: {
              fullName: referral.familyInfo.livesWith1Name,
              familyRelationshipId: referral.familyInfo.livesWith1RelationshipId,
              email: referral.familyInfo.livesWith1Email,
              workPhone: referral.familyInfo.livesWith1WorkPhone,
              cellPhone: referral.familyInfo.livesWith1CellPhone,
              homePhone: referral.familyInfo.livesWith1HomePhone,
              bestWayToReachCellPhone: referral.familyInfo.livesWith1BestWayToReachCellPhone,
              bestWayToReachHomePhone: referral.familyInfo.livesWith1BestWayToReachHomePhone,
              bestWayToReachWorkPhone: referral.familyInfo.livesWith1BestWayToReachWorkPhone,
              bestWayToReachEmail: referral.familyInfo.livesWith1BestWayToReachEmail,
              bestWayToReachText: referral.familyInfo.livesWith1BestWayToReachText,
              bestTimeToContact: referral.familyInfo.livesWith1BestTimeToContact,
              isPersonCompletingReferral: referral.referralSource === FamilyMemberType.LivesWith1,
            },
            livesWith2: {
              fullName: referral.familyInfo.livesWith2Name,
              familyRelationshipId: referral.familyInfo.livesWith2RelationshipId,
              email: referral.familyInfo.livesWith2Email,
              workPhone: referral.familyInfo.livesWith2WorkPhone,
              cellPhone: referral.familyInfo.livesWith2CellPhone,
              homePhone: referral.familyInfo.livesWith2HomePhone,
              bestWayToReachCellPhone: referral.familyInfo.livesWith2BestWayToReachCellPhone,
              bestWayToReachHomePhone: referral.familyInfo.livesWith2BestWayToReachHomePhone,
              bestWayToReachWorkPhone: referral.familyInfo.livesWith2BestWayToReachWorkPhone,
              bestWayToReachEmail: referral.familyInfo.livesWith2BestWayToReachEmail,
              bestWayToReachText: referral.familyInfo.livesWith2BestWayToReachText,
              bestTimeToContact: referral.familyInfo.livesWith2BestTimeToContact,
              isPersonCompletingReferral: referral.referralSource === FamilyMemberType.LivesWith2,
            },
          });
        }

        this.formGroup.updateValueAndValidity();
        this.formGroup.markAllAsTouched();
      },
      (error) => console.log(error)
    );
  }

  buttonToggleChanged(event: MatButtonToggleChange) {
    const control = this.formGroup.get(event.value);
    control.setValue(!control.value);
  }

  populateLocationFromZipCode() {
    const zipCodeCtrl = this.formGroup.get('zipCode');
    if (zipCodeCtrl.valid && zipCodeCtrl.value) {
      this.locationService.getLocationData(zipCodeCtrl.value).subscribe((res) => {
        if (res) {
          this.formGroup.patchValue({
            city: res.city,
            county: res.county,
            state: 'IA',
          });
        }
      });
    }
  }

  populateChildCareLocationFromZip() {
    const zipCodeCtrl = this.formGroup.get('childCareProviderZipCode');
    if (zipCodeCtrl.valid && zipCodeCtrl.value) {
      this.locationService.getLocationData(zipCodeCtrl.value).subscribe((res) => {
        if (res) {
          this.formGroup.patchValue({
            childCareProviderCity: res.city,
            childCareProviderState: 'IA',
          });
        }
      });
    }
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    this.submitAttempted = true;
    if (this.formGroup.valid && this.validBirthday) {
      const learner = this.formGroup.value as LearnerCreate;
      learner.familyMembers = this.familyMembers.filter((fm) => !!fm.get('fullName').value).map((fm) => fm.value);
      learner.familyMembers.forEach((fm) => {
        fm.familyRelationshipId = this.relationshipOptions.find((x) => x.value === 'Parent (Biological or Adoptive)').key;
      });

      learner.referralId = this.route.snapshot.paramMap.get('referralId');

      if (
        this.validBirthday &&
        LearnerService.atLeastAndUnder(6, 20, learner.dateOfBirth) &&
        this.achieveConfigService.settings.canAddAnyAgeLearner &&
        learner.eligibleInLegacySystemPartC
      ) {
        learner.eligibleInLegacySystemPartC = false;
        learner.eligibleInLegacySystem = true;
      }

      learner.dateOfBirth = new DateToUtcPipe().transform(new Date(learner.dateOfBirth));
      this.activeCall = true;
      learner.newFromSif = false;
      this.learnerService.addLearner(learner).subscribe(async (learnerSummary) => {
        learnerSummary.activeCases.forEach((activeCase) => this.authService.user.caseOwnerCases.push(activeCase.id));
        await this.addConsentFiles(learnerSummary.activeCases[0].id);
        if (learnerSummary.activeCases[0].intakeType === IntakeType.PartC) {
          await this.router.navigate(['/child-find/service-coordinator-assignment']);
        } else {
          return this.routingService.learnerDashboard(learnerSummary.id);
        }
      });
    }
  }

  // TODO: ask about ConsentForm "type". Also move to field on DTO and delete this
  addToConsentList(document: FileDocument) {
    const dialogRef: MatDialogRef<FamilyConsentAddComponent, AddConsentDialogResult> = this.dialog.open(FamilyConsentAddComponent, {
      width: '800px',
      data: {
        availConsentFormTypeOptions: this.availConsentFormTypeOptions,
        referralConsent: true,
      },
    });

    dialogRef.afterClosed().subscribe((data: AddConsentDialogResult) => {
      if (data) {
        const consentForm: ConsentForm = {
          referralDocumentId: document.id,
          caseId: null,
          type: data.type?.value,
          statuses: [
            {
              status: data.status,
              dateReceived: data.dateReceived,
              dateSigned: data.dateSigned,
              signedBy: data.signedBy,
              documents: [],
              comments: data.notes,
            },
          ],
          createdOn: new Date(),
          isComplete: false,
        };
        this.consentDocuments.push(consentForm);
        this.availConsentFormTypeOptions = this.availConsentFormTypeOptions.filter((x) => x.key !== data.type);

        this.notificationService.success('Document will be added to consent file list on submit.');
      }
    });
  }

  // TODO: ask about ConsentForm "type". Also move to field on DTO and delete this
  async addConsentFiles(caseId: string): Promise<void> {
    for (const document of this.consentDocuments) {
      document.caseId = caseId;
      await this.consentFormService.createConsentForm(caseId, document).toPromise();
    }
  }

  documentInConsent(document: FileDocument) {
    return this.consentDocuments.findIndex((x) => x.referralDocumentId === document.id) > -1;
  }

  private birthdayChange() {
    const dob = this.formGroup.get('dateOfBirth').value;
    const current915 = dayjs(`${dayjs().year()}-09-15`).toString();
    const goingToBeUnder = LearnerService.goingToBeUnder(6, dob, current915);

    if (!!dob && goingToBeUnder.result) {
      if (goingToBeUnder.agebyFutureDate === 5 && this.pageLoaded) {
        const dialogRef = this.dialog.open(AreYouSureComponent, {
          data: {
            question: 'New Child Entry Part B',
            subQuestion: this.referral
              ? `Is ${this.referral?.childInfo.firstName} attending Kindergarten?`
              : 'Is the learner attending Kindergarten?',
          },
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.dialog.open(AlertDialogComponent, {
              data: {
                title: 'New Child Entry Part B',
                message: this.referral
                  ? `To activate ${this.referral?.childInfo.firstName} in ACHIEVE, first work with the school
                         to make sure the Kindergarten registration and enrollment process is complete.`
                  : 'To activate the learner in ACHIEVE, first work with the school to make sure the Kindergarten registration and enrollment process is complete.',
              },
            });
            this.validBirthday = false;
          } else {
            this.validBirthday = true;
          }
        });
      } else {
        this.validBirthday = true;
      }
    } else {
      this.validBirthday = false || this.achieveConfigService.settings.canAddAnyAgeLearner;
    }

    if (dob && (LearnerService.atLeastAndUnder(3, 6, dob) || this.achieveConfigService.settings.canAddAnyAgeLearner)) {
      const referralSourceInfo = this.formGroup.controls.referralSourceInfo;
      if (referralSourceInfo) {
        referralSourceInfo.reset();
        referralSourceInfo.clearValidators();
        referralSourceInfo.updateValueAndValidity();
      }
    } else {
      this.setReferralSourceFormGroup();
    }
  }

  private agencyValidator(control: AbstractControl): ValidationErrors | null {
    const referralSourceCategory = control.get('referralSourceCategory');
    const agency = control.get('referralSourceAgency');

    // TODO: change this to look up model
    return referralSourceCategory && !referralSourceCategory.value?.isFamily && agency && (agency.value === '' || agency.value === null)
      ? { agencyRequired: true }
      : null;
  }

  private screeningToolValidator(formGroup: FormGroup): ValidationErrors | null {
    const cognitive = formGroup.get('referralReasonCognitive').value;
    const motor = formGroup.get('referralReasonMotor').value;
    const communication = formGroup.get('referralReasonCommunication').value;
    const adaptive = formGroup.get('referralReasonAdaptive').value;
    const social = formGroup.get('referralReasonSocialEmotional').value;
    const other = formGroup.get('referralReasonOther').value;
    const toolUsed = formGroup.get('referralReasonScreeningToolUsed').value;
    const dob = this.formGroup.get('dateOfBirth') as FormControl;

    const delaySelected = cognitive || motor || communication || adaptive || social || other;
    this.changeDetectorRef.detectChanges();
    return !isThreeToFive(dob) && delaySelected && typeof toolUsed !== 'string' ? { toolRequired: true } : null;
  }

  private toolNameValidator(formGroup: FormGroup): ValidationErrors | null {
    const toolUsed = formGroup.get('referralReasonScreeningToolUsed').value;
    const toolName = formGroup.get('screeningToolIds').value;
    const dob = this.formGroup.get('dateOfBirth') as FormControl;

    return toolUsed === !isThreeToFive(dob) && UnknownYesNo.Yes && !toolName ? { nameRequired: true } : null;
  }
}

const isThreeToFive = (dob: FormControl) => !!dob.value && dob.valid && LearnerService.atLeastAndUnder(3, 6, dob.value);

// TODO: factor our validators shared with referral to external file
const guardianValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  if (isThreeToFive(formGroup.controls.dateOfBirth as FormControl)) {
    return null;
  }

  if (
    formGroup.get('referralSource').value === FamilyMemberType.Parent1 ||
    formGroup.get('referralSource').value === FamilyMemberType.Parent2
  ) {
    return null;
  }
  return typeof formGroup.get('legalGuardian').value !== 'boolean' ? { guardianRequired: true } : null;
};

const exchangeValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  if (isThreeToFive(formGroup.controls.dateOfBirth as FormControl)) {
    return null;
  }

  if (
    formGroup.get('referralSource').value === FamilyMemberType.Parent1 ||
    formGroup.get('referralSource').value === FamilyMemberType.Parent2
  ) {
    return null;
  }
  return typeof formGroup.get('exchangeInformation').value !== 'boolean' ? { exchangeRequired: true } : null;
};

const familyMemberNameValidator: ValidatorFn = (formGroup: FormGroup) =>
  !!formGroup.get('livesWithChild').value && !formGroup.get('fullName').value ? { fullNameRequired: true } : null;

const livesWithValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  const name = formGroup.get('fullName').value;
  const livesWith = formGroup.get('livesWithChild').value;
  return !!name && typeof livesWith !== 'boolean' ? { livesWithRequired: true } : !!livesWith && !name ? { fullNameRequired: true } : null;
};

const bestWayToReachValidator =
  (parent: string): ValidatorFn =>
  (control: FormGroup): ValidationErrors | null => {
    const homePhone = control.get('homePhone');
    const homePhoneIsBest = control.get('bestWayToReachHomePhone');
    const cellPhone = control.get('cellPhone');
    const cellPhoneIsBest = control.get('bestWayToReachCellPhone');
    const textIsBest = control.get('bestWayToReachText');
    const workPhone = control.get('workPhone');
    const workPhoneIsBest = control.get('bestWayToReachWorkPhone');
    const email = control.get('email');
    const emailIsBest = control.get('bestWayToReachEmail');

    if (
      (!!homePhoneIsBest.value && !homePhone.value) ||
      (!!workPhoneIsBest.value && !workPhone.value) ||
      (!!cellPhoneIsBest.value && !cellPhone.value) ||
      (!!textIsBest.value && !cellPhone.value) ||
      (!!emailIsBest.value && !email.value)
    ) {
      const errors = {};
      errors[`${parent}OnePhoneNumberContactRequired`] = true;
      return errors;
    } else {
      return null;
    }
  };

const referralSourcePhoneValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const referralSourceHomePhone = control.get('referralSourceHomePhone');
  const referralSourceWorkPhone = control.get('referralSourceWorkPhone');
  const referralSourceCellPhone = control.get('referralSourceCellPhone');

  return referralSourceHomePhone &&
    referralSourceHomePhone.value === '' &&
    referralSourceWorkPhone &&
    referralSourceWorkPhone.value === '' &&
    referralSourceCellPhone &&
    referralSourceCellPhone.value === ''
    ? { referralSourceOnePhoneNumberRequired: true }
    : null;
};

const referralSourceAddressValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const address = control.get('referralSourceAddress');
  const city = control.get('referralSourceCity');
  const state = control.get('referralSourceState');
  const zipCode = control.get('referralSourceZipCode');

  const isInvalid = !address.value || !city.value || !state.value || !zipCode.value;

  return isInvalid ? { addressRequired: true } : null;
};

const developmentValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const cognitive = control.get('referralReasonCognitive').value;
  const motor = control.get('referralReasonMotor').value;
  const communication = control.get('referralReasonCommunication').value;
  const adaptive = control.get('referralReasonAdaptive').value;
  const social = control.get('referralReasonSocialEmotional').value;
  const other = control.get('referralReasonOther').value;
  const visionHearing = control.get('referralReasonDiagnosedCondition').value;
  const childPremature = control.get('referralReasonBornLessThan32Week').value;
  const childWeight = control.get('referralReasonBirthWeightLessThan3lb').value;
  const dhsNeglect = control.get('referralReasonAbuseOrNeglect').value;
  const dhsPIDs = control.get('referralReasonPIDs').value;
  const dhsCAPTA = control.get('referralReasonNonCAPTA').value;

  return !cognitive &&
    !motor &&
    !communication &&
    !adaptive &&
    !social &&
    !other &&
    !visionHearing &&
    !childPremature &&
    !childWeight &&
    !dhsNeglect &&
    !dhsPIDs &&
    !dhsCAPTA
    ? { delayValidator: true }
    : null;
};

const descriptionValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const otherCheckbox = control.get('referralReasonOther');
  const description = control.get('referralReasonDesc');
  return otherCheckbox.value === true && (description.value === '' || description.value === null) ? { descRequired: true } : null;
};

const birthPrematureValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const childPremature = control.get('referralReasonBornLessThan32Week');
  const weeks = control.get('referralReasonBornInWeeks');
  const days = control.get('referralReasonBornInDays');

  if (!childPremature?.value) {
    return null;
  }
  // 224 days = 32 weeks
  return (+weeks?.value || 0) * 7 + (+days?.value || 0) > 224 ? { ageInvalid: true } : null;
};

const birthWeightValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const childWeight = control.get('referralReasonBirthWeightLessThan3lb');
  const weightUnits = control.get('referralReasonBirthWeightUnits');

  switch (weightUnits.value) {
    case 'lbOz':
      const lb = parseFloat(control.get('referralReasonBirthInLb').value) || 0;
      const oz = parseFloat(control.get('referralReasonBirthInOz').value) || 0;
      return lb * 16 + oz >= 3 * 16 + 5 ? { weightInvalid: true } : null;
    case 'g':
      const g = parseFloat(control.get('referralReasonBirthInGrams').value) || 0;
      return g >= 1500 ? { weightInvalid: true } : null;
    case 'kg':
      const kg = parseFloat(control.get('referralReasonBirthInKilograms').value) || 0;
      return kg >= 1.5 ? { weightInvalid: true } : null;
    default:
      return null;
  }
};

const diagnosedConditionValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const condition = control.get('referralReasonDiagnosedCondition');
  const conditionName = control.get('referralReasonNameMedicallyDiagnosedCondition');

  return condition && condition.value === true && conditionName && conditionName.value === '' ? { conditionRequired: true } : null;
};

const atLeastOnePhoneValidator: ValidatorFn = (parentForm: FormGroup): ValidationErrors | null => {
  const livesWith1Email = parentForm.get('livesWith1.email');
  const livesWith1HomePhone = parentForm.get('livesWith1.homePhone');
  const livesWith1CellPhone = parentForm.get('livesWith1.cellPhone');
  const livesWith1WorkPhone = parentForm.get('livesWith1.workPhone');
  const livesWith2Email = parentForm.get('livesWith2.email');
  const livesWith2HomePhone = parentForm.get('livesWith2.homePhone');
  const livesWith2CellPhone = parentForm.get('livesWith2.cellPhone');
  const livesWith2WorkPhone = parentForm.get('livesWith2.workPhone');

  const parent1Email = parentForm.get('parent1.email');
  const parent1HomePhone = parentForm.get('parent1.homePhone');
  const parent1CellPhone = parentForm.get('parent1.cellPhone');
  const parent1WorkPhone = parentForm.get('parent1.workPhone');
  const parent2Email = parentForm.get('parent2.email');
  const parent2HomePhone = parentForm.get('parent2.homePhone');
  const parent2CellPhone = parentForm.get('parent2.cellPhone');
  const parent2WorkPhone = parentForm.get('parent2.workPhone');

  let livesWith = true;

  const hasFamilyInfo =
    parentForm.get('parent1.livesWithChild')?.value === false || parentForm.get('parent2.livesWithChild')?.value === false;

  if (hasFamilyInfo) {
    livesWith =
      !livesWith1Email?.value &&
      !livesWith1HomePhone?.value &&
      !livesWith1CellPhone?.value &&
      !livesWith1WorkPhone?.value &&
      !livesWith2Email?.value &&
      !livesWith2HomePhone?.value &&
      !livesWith2CellPhone?.value &&
      !livesWith2WorkPhone?.value;
  }

  const parents =
    !parent1Email?.value &&
    !parent1HomePhone?.value &&
    !parent1CellPhone?.value &&
    !parent1WorkPhone?.value &&
    !parent2Email?.value &&
    !parent2HomePhone?.value &&
    !parent2CellPhone?.value &&
    !parent2WorkPhone?.value;

  if (livesWith && parents) {
    return { atLeastOnePhoneRequired: true };
  }
  return null;
};
