import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { LearnerService } from '../../../../shared/services/learner/learner.service';
import { Subscription } from 'rxjs';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { DateTimeFormatPipe } from 'src/app/shared/pipes/date-transform.pipe';
import { CaseCreate, CaseCreateCore, SifCaseCreate } from 'src/app/shared/models/case';
import { Base64Document } from 'src/app/shared/models/base64Document';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import { OosPlacementService } from 'src/app/shared/services/oos-placement.service';

@Component({
  selector: 'app-new-case-modal',
  templateUrl: './new-case-modal.component.html',
  styleUrls: ['./new-case-modal.component.scss'],
  providers: [DateTimeFormatPipe],
})
export class NewCaseModalComponent implements OnInit, OnDestroy {
  submitting = false;
  formGroup: FormGroup;
  oospFiles: Base64Document[] = [];
  private subs = new Subscription();

  get disableMovingFromOutOfState() {
    return this.formGroup.get('eligibleInLegacySystem').value === true;
  }

  get disableEligibleInLegacySystem() {
    return this.formGroup.get('movingFromOutOfState').value === true;
  }

  get learnersName() {
    return this.data.learnerSummary.firstName;
  }

  get learnerSummary() {
    return this.data.learnerSummary;
  }

  get isPartCLearner() {
    // PartC learner DOB is at least 45 days priod to their 3rd birthday.
    if (this.learnerService.getAgeInDays(this.data.learnerSummary.dateOfBirth) < 1050) return true;
    return false;
  }

  isPossibleDuplicate: boolean;
  isOutOfState: boolean;
  isAddingFromOutOfState: boolean;

  optionsForEligibleInIowa: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  optionsForWeightedLevel: KeyValuePair[];

  constructor(
    private dialogRef: MatDialogRef<NewCaseModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public caseService: CaseService,
    public routingService: RoutingService,
    private learnerService: LearnerService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private oosPlacementService: OosPlacementService
  ) {}

  ngOnInit(): void {
    this.isOutOfState = !!this.data.isOutOfState;
    this.optionsForWeightedLevel = this.oosPlacementService.getOptionsForWeightedLevel();
    if (this.data.isFromSif) {
      this.isPossibleDuplicate = this.data.isPossibleDuplicate;
    } else {
      this.isPossibleDuplicate = false;
    }
    this.createForm();
  }

  private createForm() {
    this.formGroup = new FormGroup({
      movingFromOutOfState: new FormControl(false),
      eligibleInLegacySystem: new FormControl(false),
      addingFromOutOfState: new FormControl(false),
    });
    this.setValueChanges(this.formGroup.controls.movingFromOutOfState);
    this.setValueChanges(this.formGroup.controls.eligibleInLegacySystem);
    if (this.isOutOfState) {
      this.formGroup.addControl('iepDate', new FormControl({ value: null, disabled: true }, Validators.required));
      this.formGroup.addControl('iepDueDate', new FormControl({ value: null, disabled: true }, Validators.required));
      this.formGroup.addControl('reevalDate', new FormControl({ value: null, disabled: true }, Validators.required));
      this.formGroup.addControl('eligibleInIowa', new FormControl({ value: null, disabled: true }, Validators.required));
      this.formGroup.addControl('weightedLevel', new FormControl({ value: null, disabled: true }, Validators.required));

      this.setValueChanges(this.formGroup.controls.addingFromOutOfState, (v) => {
        this.isAddingFromOutOfState = !!v;
        this.setControlStatus(this.formGroup.controls.iepDate, !!v);
        this.setControlStatus(this.formGroup.controls.iepDueDate, !!v);
        this.setControlStatus(this.formGroup.controls.reevalDate, !!v);
        this.setControlStatus(this.formGroup.controls.eligibleInIowa, !!v);
        this.setControlStatus(this.formGroup.controls.weightedLevel, !!v);
      });
    }
  }

  private setValueChanges(ctrl: AbstractControl, additional = (v) => {}) {
    this.subs.add(
      ctrl.valueChanges.subscribe((v) => {
        this.setControlStatus(this.formGroup.controls.movingFromOutOfState, !v, ctrl);
        this.setControlStatus(this.formGroup.controls.eligibleInLegacySystem, !v, ctrl);
        this.setControlStatus(this.formGroup.controls.addingFromOutOfState, !v, ctrl);

        additional(v);
      })
    );
  }

  private setControlStatus(ctrl: AbstractControl, v: boolean, changedCtrl: AbstractControl = null) {
    if (ctrl === changedCtrl) return;

    if (v) {
      ctrl.enable({ emitEvent: false });
    } else {
      ctrl.disable({ emitEvent: false });
    }
  }

  uploadOospDocument(files: Base64Document[]) {
    this.oospFiles.push(...files);
  }

  removeOospDocument(file: any) {
    this.oospFiles = this.oospFiles.filter((x) => x !== file);
  }

  onClose() {
    this.dialogRef.close();
  }

  onYes() {
    this.submitting = true;
    const model = {} as CaseCreateCore;
    model.movingFromOutOfState = this.formGroup.get('movingFromOutOfState').value;
    model.eligibleInLegacySystem = this.formGroup.get('eligibleInLegacySystem').value;
    model.addingFromOutOfState = this.formGroup.get('addingFromOutOfState').value;
    model.referralId = this.data.referralId ?? '';

    if (model.addingFromOutOfState) {
      model.iepDate = this.formGroup.get('iepDate').value;
      model.iepDueDate = this.formGroup.get('iepDueDate').value;
      model.reevalDate = this.formGroup.get('reevalDate').value;
      model.eligibleInIowa = this.formGroup.get('eligibleInIowa').value;
      model.weightedLevel = this.formGroup.get('weightedLevel').value;
      model.documents = this.oospFiles;
    }

    if (this.data.isFromSif) {
      this.createSifCase(model);
    } else {
      this.createCase(model);
    }
  }

  private createCase(model: CaseCreateCore) {
    const caseModel = model as CaseCreate;

    caseModel.learnerId = this.learnerSummary.id;

    this.caseService.createCase(caseModel).subscribe(
      () => this.onSuccess(this.learnerSummary.id),
      (error) => this.onError(error)
    );
  }

  private createSifCase(model: CaseCreateCore) {
    const caseModel = model as SifCaseCreate;

    caseModel.studentPersonal = this.data.learnerSummary.studentPersonal;
    caseModel.currentEnrollment = this.data.learnerSummary.currentEnrollment;

    this.caseService.createSifCase(caseModel).subscribe(
      (data) => this.onSuccess(data.learnerId),
      (error) => this.onError(error)
    );
  }

  private onSuccess(learnerId: string) {
    this.submitting = false;
    if (this.authService.isAllowed(AppPermissions.ChildSearchResults)) {
      this.routingService.learnerDashboard(learnerId);
    } else {
      this.routingService.outOfStateDashboard();
    }

    this.dialogRef.close();
  }

  private onError(error: any) {
    this.notificationService.error(error?.error ?? 'Something went wrong!');
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
