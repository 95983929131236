import { ProviderInformation } from 'src/app/evaluation/shared/models/health-information';
import { Base64Document } from '../../shared/models/base64Document';
import { Building } from '../../shared/models/building';
import { District } from '../../shared/models/district';
import { FileDocument } from '../../shared/models/file-document';
import { KeyValuePair } from '../../shared/models/key-value-pair';
import { FamilyMember, Learner } from '../../shared/models/learner';
import { PaginatedList } from '../../shared/models/paginated-list';
import { User } from '../../shared/models/user';
import { DocumentationInformation } from 'src/app/evaluation/models/intake';

export class DhhLearnerSearchResultDto {
  id: string;
  stateAssignedId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  dateOfBirth: string;
  grade: string;
  gradeDescription: string;
  status: string;
  audiologistName: string;
  tdhhName: string;
  attendingAea: string;
  attendingDistrict: string;
  building: string;
  classroom: string;
  legend?: string;
  mostRecentFinalizedResultDate?: string;
  mostRecentFinalizedResult?: string;
  screeningResult?: string;
  screeningDate?: string;
  screeningNotes?: string;
  submitted?: boolean;
  finalized?: boolean;
  isSif: boolean;
  zoneId: string;
  isDhh: boolean;
  isDoNotTest: boolean;
  isDoNotScreen: boolean;
}

export class DhhCaseDto {
  id: string;
  stateAssignedId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  dateOfBirth: string;
  grade: string;
  status: string;
  audiologistName: string;
  tdhhName: string;
  attendingAea: string;
  attendingDistrict: string;
  building: string;
  activeCases: string;
  hasAssistiveTech?: boolean;
  isDhh: boolean;
  isDoNotTest: boolean;
  isDoNotScreen: boolean;
}

export interface DhhCaseList {
  caseList: PaginatedList<DhhCaseDto>;
  filterOptions: { districts: Array<District>; buildings: Array<Building> };
}

export class DhhLearnerDto {
  learnerId: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  dateOfBirth: string;
  age: string;
  grade: string;
  gradeDescription: string;
  stateAssignedId: string;
  attendingAeaId: string;
  attendingAea: string;
  attendingDistrictId: string;
  attendingDistrict: string;
  buildingId: string;
  attendingBuilding: string;
  attendingBuildingSifSchoolInfoRefId: string;
  residentAeaId: string;
  residentAea: string;
  residentDistrictId: string;
  residentDistrict: string;
  teachersName: string;
  birthHospital: string;
  hadNICUCare: string;
  numberOfDaysInNICU: string;
  isThereHearingLossHistory: string;
  otherNotes: string;
  audiologistUserId: string;
  riskFactors: string[];
  familyMembers: DhhFamilyMember[];
  isDhh: boolean;
  isDoNotTest: boolean;
  isDoNotScreen: boolean;
  activeCaseId: string;
  testsCount: number;
  anyTestsInProgress: boolean;
  assistiveTechCount: number;
  documentsCount: number;
  assessmentsCount: number;
  learnerExited?: boolean;

  providerInformation: ProviderInformation;
  documentationInformation: DocumentationInformation;
}

export interface DhhLearnerWithScreeningResultDto extends DhhLearnerDto {
  mostRecentFinalizedResultDate: Date;
  mostRecentFinalizedResult: string;
}

export interface DhhScreeningSearchDto extends DhhLearnerDto {
  mostRecentScreeningId: string;
  mostRecentScreeningOptionId: string;
  mostRecentScreeningResult?: string;
  mostRecentScreeningDate?: string;
  screeningNotes?: string;
  isSubmitted?: boolean;
  isFinalized?: boolean;
  mostRecentFinalizedResult?: string;
  mostRecentFinalizedResultDate?: string;
  selected: boolean;
}

export interface DhhRecheckSearchDto extends DhhScreeningSearchDto {
  hasOpenDhhTest: boolean;
  openDhhTestId: string;
  selected: boolean;
}

export interface DhhLearnerScreeningDto {
  id: string;
  learnerId: string;
  learner: DhhLearnerDto;
  screeningOptionId: string;
  screeningNotes: string;
  screeningDate: Date;
  passCriteriaJson: string;
  isSubmitted?: boolean;
  isFinalized?: boolean;
  finalizedOn?: Date;
  screeningSource: DhhScreeningSource;
}

export enum DhhScreeningSource {
  Screening,
  Recheck,
}

export interface DhhLearnerGeneralInformationDto {
  learnerId: string;
  dateOfBirth: Date;
  age: number;
  activeCaseId: string;
  tddhAssigned: string[];
  audiologistUserId: string;
  audiologist: string;
  isDhh: boolean;
  isDoNotTest: boolean;
  isDoNotScreen: boolean;
  isAlternateAssessment: boolean;
  is504: boolean;
  isSharedInfo: boolean;
  dateShared: Date;
  isInfoSharedWithLearner: boolean;
  isInfoSharedWithFamily: boolean;
  isInfoSharedWithSchoolPersonnel: boolean;
  isPreEtsTransitionServices: boolean;
  preEtsTransitionServicesDate: Date;
  isDeafblind: boolean;
  familySupportIds: string[];
  familySupportOther: string;
  expressiveCommunicationModeIds: string[];
  expressiveCommunicationModeOther: string;
  receptiveCommunicationModeIds: string[];
  receptiveCommunicationModeOther: string;
  followUpDate: Date;
  isBooth?: boolean;
  attendingAeaId: string;
  attendingDistrictId: string;
  attendingBuildingId: string;
  audiogramValues: DhhTestAudiogramValueDto[];
  hasAnyFinalizedTests: boolean;
  familyRecommendations: DhhLearnerRecommendationDto[];
  schoolRecommendations: DhhLearnerRecommendationDto[];
  isDhhExited?: boolean;
  isAchieveExited?: boolean;
  sifExitDate?: Date;
  sifExitCode?: string;
}

export interface DhhSpecialDesignationHistoryDto {
  learnerId: string;
  dateChanged: Date;
  value: boolean;
  type: DhhSpecialDesignationHistoryType;
  reason: string;
  userId: string;
  user: string;
  aeaName: string;
}

export enum DhhSpecialDesignationHistoryType {
  Dhh,
  DoNotTest,
  DoNotScreen,
}

export interface DhhLearnerAudiologistHistoryDto {
  learnerId: string;
  dateChanged: Date;
  audiologistId: string;
  audiologist: string;
  aeaName: string;
}

export interface DhhLearnerRecommendationDto {
  recommendationId: string;
  label: string;
  other: string;
}

export interface DhhLearnerRecommendationUpdateDto {
  learnerId: string;
  familyRecommendations: DhhLearnerRecommendationDto[];
  schoolRecommendations: DhhLearnerRecommendationDto[];
}

export interface DhhAddLearnerFromSifDto {
  refId: string;
  zoneId: string;
}

export interface DhhFamilyMember extends FamilyMember {
  temporaryId: string;
}

export enum DhhType {
  DHHU3 = 'DHHU3',
  DHH = 'DHH',
}

export enum LearnerAppointmentStatus {
  Pending = 'Pending',
  Kept = 'Kept',
  Cancelled = 'Cancelled',
  Rescheduled = 'Rescheduled',
}

export interface DhhAppointmentDto {
  id: string;
  appointmentTypeId: string;
  appointmentType: DhhLookupDto;
  districtId: string;
  districtName: string;
  buildingId: string;
  buildingName: string;
  soundBoothLocationId: string;
  startDate: Date;
  endDate?: Date;
  startTime?: string;
  endTime?: string;
  audiologistIds: string[];
  audiometristIds: string[];
  notes: string;
  learnerAppointments: DhhLearnerAppointmentDto[];
}

export interface DhhLearnerAppointmentDto {
  id: string;
  dhhAppointmentId: string;
  learnerId: string;
  learner: Learner;
  appointmentTypeId: string;
  appointmentType: DhhLookupDto;
  eventStartDate?: Date;
  eventLocation?: any;
  eventAudiologists?: User[];
  startTime: string;
  endTime: string;
  cancellationReasonId: string;
  rescheduleReasonId: string;
  status: LearnerAppointmentStatus;
  participants: DhhLearnerAppointmentParticipantDto[];
}

export interface DhhLearnerAppointmentParticipantDto {
  id: string;
  learnerAppointmentId: string;
  familyMemberId: string;
  fullName: string;
  email: string;
  familyRelationshipId: string;
  familyRelationship: DhhLookupDto;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
}

export class DhhLookupDto {
  id: string;
  label: string;
  order: number;
}

export class DhhPendingAppointmentDto {
  dhhAppointmentId: string;
  learnerAppointmentId: string;
  appointmentDate: Date;
  startTime: string;
  endTime: string;
  learnerId: string;
  learnerName: string;
  dateOfBirth: string;
  appointmentType: string;
  audiologists: string;
  birthHospital: string;
  nameOfBooth: string;
  status: LearnerAppointmentStatus;
  selected?: boolean;
}

export class DhhLearnerAppointmentLetterDto {
  learnerId: string;
  learnerAppointmentId: string;
  confirmationLetterId: string;
}

export interface DhhEventDto extends DhhAppointmentDto {
  appointmentId: string;
  soundBoothLocation: DhhLookupDto;
  audiologists: User[];
  audiometrists: User[];
  status: string;
  startDateAndTime: Date;
  hasOpenAppointment: boolean;
}

export interface DhhListSearchParams {
  attendingDistrictId: string;
  attendingAeaId: string;
  buildingId: string;
  grade: string;
  planStatus: string;
  inactiveReason: string;
  mostRecentFinalizedResults: string;
  mostRecentFinalizedResultsDateStart: string;
  mostRecentFinalizedResultsDateEnd: string;
  followUpBoothDateStart: string;
  followUpBoothDateEnd: string;
  followUpNonBoothDateStart: string;
  followUpNonBoothDateEnd: string;
  dhhStatus: boolean;
  doNotTest: boolean;
  doNotScreen: boolean;
  tdhhService: boolean;
  assistiveTechnology: boolean;
  interpreterService: boolean;
  alternateAssessment: boolean;
}

export interface DhhScreeningRecheckFilters {
  aeaId: string;
  districtId: string;
  buildingId: string;
  grade: string;
  searchType: DhhScreeningRecheckSearchType;
  teacherId?: string;
  startDate?: string;
  endDate?: string;
  learnerLastName?: string;
  learnerFirstName?: string;
  learnerDOB?: string;
  learnerStateAssignedId?: string;
}

export enum DhhScreeningRecheckSearchType {
  All = 'All',
  OnlyOne = 'OnlyOne',
  WithoutScreening = 'WithoutScreening',
  IdentifiedForRecheck = 'IdentifiedForRecheck',
}

export interface DhhLearnerNoteUploadDto {
  id: string;
  learnerId: string;
  date: Date;
  userId: string;
  categoryId: string;
  categoryOther: string;
  methodId: string;
  note: string;
  documents: Base64Document[];
}

export interface DhhLearnerNoteReadDto {
  id: string;
  learnerId: string;
  date: Date;
  userId: string;
  user: string;
  categoryId: string;
  category: string;
  categoryOther: string;
  method: string;
  methodId: string;
  note: string;
  documents: FileDocument[];
}

export interface DhhLhdLookupsDto {
  familySupports: DhhLookupDto[];
  expressiveCommunicationModes: DhhLookupDto[];
  receptiveCommunicationModes: DhhLookupDto[];
  conductions: DhhLookupDto[];
  frequencies: DhhLookupDto[];
  familyRecommendations: DhhLookupDto[];
  schoolRecommendations: DhhLookupDto[];
}

export interface DhhNoteLookupsDto {
  categories: DhhLookupDto[];
  methods: DhhLookupDto[];
}

export interface DhhTestLookupsDto {
  testResults: DhhLookupDto[];
  testRatings: DhhLookupDto[];
  testMethods: DhhLookupDto[];
  purposesOfTesting: DhhLookupDto[];
  familyRecommendations: DhhLookupDto[];
  schoolRecommendations: DhhLookupDto[];
  actions: DhhLookupDto[];
  hearingLossLevels: DhhLookupDto[];
  hearingLossTypes: DhhLookupDto[];
  soundLevels: DhhLookupDto[];
  aabrs: DhhLookupDto[];
  conductions: DhhLookupDto[];
  frequencies: DhhLookupDto[];
  didNotTestBelows: DhhLookupDto[];
  audiologistAeas: any[];
  earResults: DhhLookupDto[];
  stimuli: DhhLookupDto[];
  frequencyLevels: DhhLookupDto[];
  tympanometryTypes: DhhLookupDto[];
  tympanometryFrequencies: DhhLookupDto[];
  sides: DhhLookupDto[];
  otoscopyOptions: DhhLookupDto[];
  speechAudiometryOptions: DhhLookupDto[];
  satResults: DhhLookupDto[];
  srtResults: DhhLookupDto[];
  wordDiscriminationResults: DhhLookupDto[];
  otoacousticEmissions: DhhLookupDto[];
  dpoaeResults: DhhLookupDto[];
}

export interface DhhTestSummaryDto {
  id: string;
  date: Date;
  type: string;
  resultId: string;
  result: string;
  grade: string;
  administeredById: string;
  administeredBy: string;
  finalizedDate: Date;
  passCriteriaJson: string;
  screeningNotes: string;
}

export interface DhhLearnerTestDto {
  id: string;
  learnerId: string;
  dhhTestInfo: DhhTestInfoDto;
  dhhOverallTestingResults: DhhOverallTestingResultsDto;
  dhhLearnerTestEquipment: DhhLearnerTestEquipmentSectionDto;
  dhhTestResult: DhhTestResultDto;
  dhhTestFamilyRecommendations: DhhTestFamilyRecommendationsDto;
  dhhTestSchoolRecommendations: DhhTestSchoolRecommendationsDto;
  dhhTestActions: DhhTestActionDto[];
  dhhLearnerTestSummary: DhhLearnerTestSummaryDto;
  dhhTestAbrStimulus: DhhLearnerTestAbrStimulusDto;
  dhhTestAbrAirUnmasked: DhhLearnerTestAbrLevelDto;
  dhhTestAbrAirMasked: DhhLearnerTestAbrLevelDto;
  dhhTestAbrBoneUnmasked: DhhLearnerTestAbrLevelDto;
  dhhTestAbrBoneMasked: DhhLearnerTestAbrLevelDto;
  dhhTestAbrResult: DhhLearnerTestAbrResultDto;
  dhhTestAabr: DhhLearnerTestAabrDto;
  dhhTestSii: DhhLearnerTestSiiDto;
  dhhTestSpeechAudiometry: DhhLearnerTestSpeechAudiometryDto;
  dhhTestSpeechAudiometryRightTest: DhhLearnerTestSpeechAudiometryTestDto;
  dhhTestSpeechAudiometryLeftTest: DhhLearnerTestSpeechAudiometryTestDto;
  dhhTestSpeechAudiometrySoundfieldTest: DhhLearnerTestSpeechAudiometryTestDto;
  dhhTestSpeechAudiometryAidedTest: DhhLearnerTestSpeechAudiometryTestDto;
  dhhTestAudiogram: DhhTestAudiogramDto;
  dhhTestTympanometry: DhhTestTympanometryDto;
  dhhTestAcousticReflexes: DhhTestAcousticReflexesDto;
  dhhTestOtoscopy: DhhTestOtoscopyDto;
  dhhTestDpoae: DhhTestDpoaeDto;
  isFinalized: boolean;
}

export interface DhhTestInfoDto {
  testPerformedBy: string;
  testingDate: Date;
  isBooth?: boolean;
  isLocationAtLearnersSchool?: boolean;
  locationOther: string;
  dhhSoundBoothLocationId: string;
}

export interface DhhOverallTestingResultsDto {
  reliabilityRatingId: string;
  testEnvironmentRatingId: string;
  dhhTestMethodId: string;
  dhhPurposeOfTestingId: string;
  purposeOfTestingOther: string;
}

export interface DhhTestResultDto {
  dhhTestResultId: string;
}

export interface DhhLearnerTestEquipmentDto {
  audiometers: DhhTestingEquipmentInfoDto[];
  tympanometers: DhhTestingEquipmentInfoDto[];
  oaes: DhhTestingEquipmentInfoDto[];
  abrs: DhhTestingEquipmentInfoDto[];
  aabrs: DhhTestingEquipmentInfoDto[];
}

export interface DhhTestingEquipmentInfoDto {
  id: string;
  isAssigned: boolean;
  label: string;
  typeId: string;
}

export interface DhhLearnerTestEquipmentSectionDto {
  audiometerId: string;
  isAudiometerHeadphones: boolean;
  isAudiometerInserts: boolean;
  tympanometerId: string;
  oaeId: string;
  abrId: string;
  isAbrHeadphones: boolean;
  isAbrInserts: boolean;
  isAbrBone: boolean;
  aabrId: string;
}

export interface DhhTestFamilyRecommendationsDto {
  recommendations: DhhTestFamilyRecommendationDto[];
  previousRecommendations: string[];
}

export interface DhhTestFamilyRecommendationDto {
  id: string;
  other: string;
}

export interface DhhTestSchoolRecommendationsDto {
  recommendations: DhhTestSchoolRecommendationDto[];
  previousRecommendations: string[];
}

export interface DhhTestSchoolRecommendationDto {
  id: string;
  other: string;
}

export interface DhhTestActionDto {
  id: string;
  other?: string;
  followUpDate?: Date;
}

export interface DhhTestAudiogramDto {
  didNotTestBelowId: string;
  passCriteriaJson: string;
  values: DhhTestAudiogramValueDto[];
}

export interface DhhTestAudiogramValueDto {
  dhhTestAudiogramConductionId: string;
  dhhTestingEquipmentFrequencyId: string;
  value: string;
}

export interface DhhTestTympanometryDto {
  rightDhhTympanometryTypeId: string;
  rightTympanometryTypeOther: string;
  rightVolume: number;
  rightPressure: number;
  rightCompliance: number;
  rightGradient: number;
  rightDhhTympanometryFrequencyId: string;
  leftDhhTympanometryTypeId: string;
  leftTympanometryTypeOther: string;
  leftVolume: number;
  leftPressure: number;
  leftCompliance: number;
  leftGradient: number;
  leftDhhTympanometryFrequencyId: string;
  rightResultId: string;
  rightResultOther: string;
  leftResultId: string;
  leftResultOther: string;
}

export interface DhhTestAcousticReflexesDto {
  rightFiveHundred: string;
  rightOneThousand: string;
  rightTwoThousand: string;
  rightFourThousand: string;
  rightSideId: string;
  leftFiveHundred: string;
  leftOneThousand: string;
  leftTwoThousand: string;
  leftFourThousand: string;
  leftSideId: string;
  rightAcousticReflexes: string;
  leftAcousticReflexes: string;
  additionalInformation: string;
}

export interface DhhTestOtoscopyDto {
  rightOtoscopyId: string;
  leftOtoscopyId: string;
  rightOtoscopyOther: string;
  leftOtoscopyOther: string;
}

export interface DhhTestDpoaeDto {
  rightDpTwoThousandLeft: number;
  rightDpTwoThousandRightId: string;
  rightDpThreeThousandLeft: number;
  rightDpThreeThousandRightId: string;
  rightDpFourThousandLeft: number;
  rightDpFourThousandRightId: string;
  rightDpFiveThousandLeft: number;
  rightDpFiveThousandRightId: string;
  rightNoiseTwoThousandLeft: number;
  rightNoiseThreeThousandLeft: number;
  rightNoiseFourThousandLeft: number;
  rightNoiseFiveThousandLeft: number;
  leftDpTwoThousandLeft: number;
  leftDpTwoThousandRightId: string;
  leftDpThreeThousandLeft: number;
  leftDpThreeThousandRightId: string;
  leftDpFourThousandLeft: number;
  leftDpFourThousandRightId: string;
  leftDpFiveThousandLeft: number;
  leftDpFiveThousandRightId: string;
  leftNoiseTwoThousandLeft: number;
  leftNoiseThreeThousandLeft: number;
  leftNoiseFourThousandLeft: number;
  leftNoiseFiveThousandLeft: number;
  rightResultId: string;
  rightResultOther: string;
  leftResultId: string;
  leftResultOther: string;
}

export interface DhhTestFamilyRecommendations {
  id?: string;
  dhhLearnerTestId: string;
}

export interface DhhLearnerTestSummaryDto {
  id?: string;
  dhhLearnerTestId: string;

  summary: string;

  configurationRightId?: string;
  configurationRightOther?: string;
  configurationLeftId?: string;
  configurationLeftOther?: string;
  configurationSoundfieldId?: string;
  configurationSoundfieldOther?: string;

  degreeOfHearingLossRightId?: string;
  degreeOfHearingLossRightOther?: string;
  degreeOfHearingLossLeftId?: string;
  degreeOfHearingLossLeftOther?: string;
  degreeOfHearingLossSoundfieldId?: string;
  degreeOfHearingLossSoundfieldOther?: string;

  typeOfHearingLossRightId?: string;
  typeOfHearingLossRightOther?: string;
  typeOfHearingLossLeftId?: string;
  typeOfHearingLossLeftOther?: string;
  typeOfHearingLossSoundfieldId?: string;
  typeOfHearingLossSoundfieldOther?: string;
}

export interface DhhLearnerTestAbrStimulusDto {
  id?: string;
  dhhLearnerTestId: string;
  fiveHundredId?: string;
  oneThousandId?: string;
  twoThousandId?: string;
  fourThousandId?: string;
}

export interface DhhLearnerTestAbrLevelDto {
  id?: string;
  dhhLearnerTestId: string;

  rightEarFiveHundredId?: string;
  rightEarOneThousandId?: string;
  rightEarTwoThousandId?: string;
  rightEarFourThousandId?: string;
  rightEarClickId?: string;

  leftEarFiveHundredId?: string;
  leftEarOneThousandId?: string;
  leftEarTwoThousandId?: string;
  leftEarFourThousandId?: string;
  leftEarClickId?: string;
}

export interface DhhLearnerTestAbrResultDto {
  id?: string;
  dhhLearnerTestId: string;

  rightEarNormalSweep?: boolean;
  rightEarDidNotTest?: boolean;
  rightEarOther?: string;
  leftEarNormalSweep?: boolean;
  leftEarDidNotTest?: boolean;
  leftEarOther?: string;
}

export interface DhhLearnerTestAabrDto {
  id?: string;
  dhhLearnerTestId: string;

  rightId?: string;
  rightOther?: string;
  leftId?: string;
  leftOther?: string;
}

export interface DhhLearnerTestSpeechAudiometryDto {
  dhhLearnerTestId: string;
  satOptionId?: string;
  srtOptionId?: string;
  wordDiscriminationOptionId?: string;
}

export interface DhhLearnerTestSpeechAudiometryTestDto {
  id?: string;
  dhhLearnerTestId: string;

  saSatLevel?: number;
  saSrtLevel?: number;
  saMaskingLevel?: number;
  wdLevel?: number;
  wdScore?: number;
  wdMaskingLevel?: number;

  satResultId?: string;
  satResultOther?: string;
  srtResultId?: string;
  srtResultOther?: string;
  wordDiscriminationResultId?: string;
  wordDiscriminationResultOther?: string;
}

export interface DhhLearnerTestSiiDto {
  id?: string;
  dhhLearnerTestId: string;

  right?: number;
  left?: number;
}

export interface DhhLearnerTestSoundboothLocationDto {
  id: string;
  nameOfBooth: string;
  appointments: DhhAppointmentDto[];
}

export interface DhhAddUpdateLearnerAssistiveTech extends DhhLearnerAssistiveTech {
  pendingAttachments?: Base64Document[];
}

export interface DhhLearnerAssistiveTech {
  id?: string;
  aeaId?: string;
  aeaLabel?: string;
  districtId?: string;
  districtLabel?: string;
  buildingId?: string;
  buildingLabel?: string;

  typeId: string;
  typeLabel?: string;
  typeOther?: string;
  sideId: string;
  sideLabel?: string;
  manufacturer: string;
  model?: string;
  serialNumber?: string;
  inventoryTag?: string;
  batterySizeId?: string;
  batterySizeLabel?: string;
  batterySizeOther?: string;
  dateOfPurchase?: Date;
  warrantyExpiration?: Date;
  assignedTo?: string;
  ownerId: string;
  ownerLabel?: string;
  outOfStateAgency?: string;
  statusId: string;
  statusLabel?: string;
  storageLocation?: string;
  accessories?: string;

  learnerId: string;
  learnerName?: string;
  unassignedById?: string;
  unassignedByLabel?: string;
  unassignedOn?: Date;

  earmoldOrDomeManfacturer?: string;
  earmoldOrDomeStyle?: string;
  earmoldOrDomeMaterial?: string;
  earmoldOrDomeColor?: string;
  earmoldOrDomeTubingSize?: string;
  earmoldOrDomeVenting?: string;

  slimtubesOrEarhooksEarhookType?: string;
  slimtubesOrEarhooksSlimTubeSize?: string;
  slimtubesOrEarhooksDomeStyle?: string;
  slimtubesOrEarhooksDomeSize?: string;

  attachments?: DhhAssistiveTechAttachment[];
  learnerAttachments?: DhhLearnerAssistiveTechnologyAttachment[];
}

export interface DhhAssistiveTechAttachment {
  id: string;
  title: string;
  fileName: string;
  soundBoothLocationId: string;
  createdOn?: Date;
}

export interface DhhLearnerAssistiveTechnologyAttachment {
  id: string;
  title: string;
  fileName: string;
  createdOn?: Date;
}

export interface DhhAssessmentLookupDto {
  administrators: DhhLookupDto[];
  assessmentTypes: DhhLookupDto[];
}

export interface DhhAssessmentSearchDto {
  id: string;
  learnerId: string;
  nameOfAssessment: string;
  dateOfAssessment: string;
  whoAdministered: string;
  ageAtTimeOfTest: number;
  designation: string;
  createdById: string;
}

export interface DhhAssessmentDto {
  id: string;
  learnerId: string;
  assessmentTypeId: string;
  administeredById: string;
  administeredByOther: string;
  assessmentDate: string;
  offerDecliened: string;
  additionalComments: string;
  socialCommunicationRawScore: number;
  spatialVerbRawScore: number;
  directionalVerbRawScore: number;
  agreementVerbRawScore: number;
  aspectRawScore: number;
  manneRawScore: number;
  additionalCommentsRawScore: number;
  narrativeSkillsTotalRawScore: number;
  narrativeSkillsPercent: number;
  narrativeContentStructureRawScore: number;
  narrativeContentStructurePercentile: number;
  aslGrammarRawScore: number;
  aslGrammarTotalPercentile: number;
  roleShiftRawScore: number;
  roleShiftTotalPercentile: number;
  questionsRawScore: number;
  questionsPercentile: number;
  totalRawScore: number;
  totalPercentile: number;
  vocabularyCheckRawScore: number;
  numberDistributionRawScore: number;
  negotiationRawScore: number;
  nounVerbRawScore: number;
  spatialVerbsLocationRawScore: number;
  spatialVerbsActionRawScore: number;
  sizeAndShapeClassifiersRawScore: number;
  handlingClassifiersRawScore: number;
  conditionalsRawScore: number;
  standardScore: number;
  celf5Scores: string[];
  coreStandardScore: number;
  coreSentenceComprehensionRawScore: number;
  coreSentenceComprehensionScaledScore: number;
  coreWordStructureRawScore: number;
  coreWordStructureScaledScore: number;
  coreFormulatedSentencesRawScore: number;
  coreFormulatedSentencesScaledScore: number;
  coreRecallingSentencesRawScore: number;
  coreRecallingSentencesScaledScore: number;
  receptiveStandardScore: number;
  receptiveSentenceComprehensionRawScore: number;
  receptiveSentenceComprehensionScaledScore: number;
  receptiveWordClassesRawScore: number;
  receptiveWordClassesScaledScore: number;
  receptiveFollowingDirectionsRawScore: number;
  receptiveFollowingDirectionsScaledScore: number;
  expressiveStandardScore: number;
  expressiveWordStructureRawScore: number;
  expressiveWordStructureScaledScore: number;
  expressiveFormulatedSentencesRawScore: number;
  expressiveFormulatedSentencesScaledScore: number;
  expressiveRecallingSentencesRawScore: number;
  receptiveRecallingSentencesScaledScore: number;
  totalScore: number;
  comMatrixPercentage: number;
  expressiveRawScore: number;
  receptiveRawScore: number;
  communicationRawScore: number;
  communicationCombinedScore: number;
  communicationDomainStandardScore: number;
  plevel: string;
  auditoryComprehensionRawScore: number;
  auditoryComprehensionStandardScore: number;
  expressiveCommunicationRawScore: number;
  expressiveCommunicationStandardScore: number;
  summationOfStandardScores: number;
  totalLanguageStandardScore: number;
  expressiveLanguageRawScore: number;
  expressiveLanguageStandardScore: number;
  receptiveLanguageRawScore: number;
  receptiveLanguageStandardScore: number;
  compositeLanguageAbilityStandardScore: number;
  sumOfLanguageAbilitySubtestStandardScores: number;
  expressiveUnitScore: number;
  receptiveUnitScore: number;
  averageLanguageAgeMonths: number;
  basalAgeRangeStart: string;
  basalAgeRangeEnd: string;
  designation: string;
}

export interface DhhConsentAgencyDto {
  id: string;
  aeaId: string;
  agencyProgramName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  contactFax: string;
}

export enum DhhFormType {
  blankLetterhead = 'blankLetterhead',
  speechBanana = 'speechBanana',
  medicalInformationRequestLetter = 'medicalInformationRequestLetter',
  scheduleHearingAppointmentLetter = 'scheduleHearingAppointmentLetter',
  scheduleABRAppointmentLetter = 'scheduleABRAppointmentLetter',
  learnerSummary = 'learnerSummary',
}

export interface DhhFormInputModel {
  learnerId: string;
  formType: string;
  formFields: DhhFormField[];
}

export interface DhhFormField {
  fieldName: string;
  fieldValue: string;
}

export interface DhhExitLookup {
  exitReasonOptions: KeyValuePair[];
  defaultExitReasonId: string;
  defaultGraduationDate: Date;
}

export interface DhhExitDto {
  learnerId: string;
  exitReasonId: string;
  graduationDate: Date;
}

export interface DhhDocumentDto {
  id?: string;
  categoryId: string;
  title: string;
  fileName: string;
  type: string;
  createdOn?: Date;
}
