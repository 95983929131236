import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../../../auth/auth.service';
import { AreYouSureComponent } from '../../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { DhhLearnerSearchResultDto } from '../../../models/DhhDtos';
import { DhhLearnerAppointmentsDetailComponent } from '../modals/dhh-learner-appointments-detail/dhh-learner-appointments-detail.component';

@Component({
  selector: 'app-dhh-learners-list',
  templateUrl: './dhh-learners-list.component.html',
  styleUrls: ['./dhh-learners-list.component.scss'],
})
export class DhhLearnersListComponent implements OnInit, OnChanges {
  @Input() learners: DhhLearnerSearchResultDto[];
  @Input() displayedColumns: string[];

  @Output() updateEvent = new EventEmitter<DhhLearnerSearchResultDto>();
  @Output() deleteEvent = new EventEmitter<DhhLearnerSearchResultDto>();
  @Output() viewMoreEvent = new EventEmitter<DhhLearnerSearchResultDto>();

  dataSource = new MatTableDataSource<DhhLearnerSearchResultDto>([]);

  get canDelete() {
    return this.authService?.isSuperAdmin || this.authService?.isDataLead || this.authService?.isDataTechnician;
  }

  get canEdit() {
    return (
      this.authService?.isSuperAdmin ||
      this.authService?.isDataLead ||
      this.authService?.isDataTechnician ||
      this.authService?.isDhhAudiologist ||
      this.authService?.isDhhAudiometrist ||
      this.authService?.isDhhScheduler
    );
  }

  get canViewAppointments() {
    return (
      this.authService?.isSuperAdmin ||
      this.authService?.isDataLead ||
      this.authService?.isDataTechnician ||
      this.authService.isDhhAudiologist ||
      this.authService.isDhhAudiometrist ||
      this.authService.isDhhScheduler
    );
  }

  constructor(private dialog: MatDialog, public readonly authService: AuthService) {}

  ngOnInit(): void {
    this.dataSource.data = this.learners;
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'learners') {
        const changedProp = changes[propName];
        this.dataSource.data = changedProp.currentValue;
      }
    }
  }

  onEdit(learner: DhhLearnerSearchResultDto) {
    this.updateEvent.emit(learner);
  }

  onDelete(learner: DhhLearnerSearchResultDto) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion:
          'By removing this learner from the pending list, all information for this learner will be removed and will not be able to be accessed again in the future. Please confirm that you wish to continue.',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.deleteEvent.emit(learner);
      }
    });
  }

  onViewMore(learner: DhhLearnerSearchResultDto) {
    this.viewMoreEvent.emit(learner);
  }

  onViewAppointments(learnerId: string) {
    this.dialog.open(DhhLearnerAppointmentsDetailComponent, {
      width: '1050px',
      data: {
        learnerId: learnerId,
      },
    });
  }
}
