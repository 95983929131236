import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { KeyValuePair } from '../shared/models/key-value-pair';
import { FamilyMemberService } from '../shared/services/family-member/family-member.service';
import { FamilyMemberNeedingValidationDto } from '../portal/model/family-member-dto';
import { MatTableDataSource } from '@angular/material/table';
import { shortDateFormat } from '../shared/dateTimeHelpers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-family-member-validation-dashboard',
  templateUrl: './family-member-validation-dashboard.component.html',
  styleUrls: ['./family-member-validation-dashboard.component.scss'],
})
export class FamilyMemberValidationDashboardComponent implements OnInit {
  formGroup: FormGroup;
  buildingOptions: KeyValuePair[];
  dataSource: MatTableDataSource<FamilyMemberNeedingValidationDto>;
  displayedColumns: string[] = ['name', 'dateOfBirth', 'caseManager', 'grade', 'attendingBuilding'];
  shortDateFormat = shortDateFormat;

  constructor(private readonly familyMemberService: FamilyMemberService, private readonly router: Router) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null),
      building: new FormControl(null),
    });

    this.formGroup.valueChanges.subscribe((value) => {
      if (!!this.dataSource) {
        const building = this.buildingOptions.find((x) => x.key === value.building);
        const model = { name: value.name, building: building?.key };
        this.dataSource.filter = JSON.stringify(model);
      }
    });

    this.familyMemberService.getFamilyMembersNeedingValidation().subscribe((res) => {
      if (res.succeeded) {
        this.buildingOptions = res.value
          .filter((x) => x.attendingBuilding)
          .map((x) => new KeyValuePair(x.attendingBuilding, x.attendingBuilding))
          .reduce((unique, o) => {
            if (!unique.some((obj) => obj.key === o.key)) {
              unique.push(o);
            }
            return unique;
          }, [])
          .sort((a, b) => a.value.localeCompare(b.value));
        this.dataSource = new MatTableDataSource(res.value);
        this.dataSource.filterPredicate = (data, filter) => {
          const { name, building } = JSON.parse(filter);
          return (
            (!name || data.name?.trim().toLowerCase().includes(name.trim().toLowerCase())) &&
            (!building || data.attendingBuilding?.includes(building))
          );
        };
      }
    });
  }

  clearFilters() {
    this.formGroup.reset();
  }

  onNavigateToLearnerDashboard(learnerId: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/', 'learner-management', learnerId, 'family-contact']));
    window.open(url);
  }
}
