import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../auth/auth.service';
import { KeyValuePair } from '../../../../shared/models/key-value-pair';
import { DhhAppointmentDto } from '../../../models/DhhDtos';
import { DhhLookupsService } from '../../../services/dhh-lookups.service';
import { DhhService } from '../../../services/dhh.service';
import { DhhAppointmentTypeIds } from '../../../models/static-lookups';

@Component({
  selector: 'app-dhh-event-scheduler',
  templateUrl: './dhh-event-scheduler.component.html',
  styleUrls: ['./dhh-event-scheduler.component.scss'],
})
export class DhhEventSchedulerComponent implements OnInit, OnDestroy {
  pageTitle = 'Scheduler';
  private subscriptions = new Subscription();
  private buildings: any[] = [];
  buildingOptions: KeyValuePair[] = [];
  districtOptions: KeyValuePair[] = [];
  appointmentTypeOptions: KeyValuePair[] = [];
  soundBoothLocationOptions: KeyValuePair[] = [];
  audiologistOptions: KeyValuePair[] = [];
  audiometristOptions: KeyValuePair[] = [];
  appointment: DhhAppointmentDto;

  formGroup: FormGroup;
  isSaving = false;
  screeningEnabled = false;
  recheckEnabled = false;
  soundboothEnabled = false;

  get canAddSoundBooth() {
    return !!this.authService.isDhhAudiologist || !!this.authService.isDhhScheduler || !!this.authService.isSuperAdmin;
  }

  get getScreeningFormGroup() {
    return this.formGroup.get('screening') as FormGroup;
  }

  get getRecheckFormGroup() {
    return this.formGroup.get('recheck') as FormGroup;
  }

  get getSoundboothFormGroup() {
    return this.formGroup.get('soundbooth') as FormGroup;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly dhhLookupService: DhhLookupsService,
    private readonly dhhService: DhhService,
    public readonly authService: AuthService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getLookups().then(() => {
      const appointmentId = this.route.snapshot.paramMap.get('appointmentId');
      if (appointmentId) {
        this.dhhService.getAppointment(appointmentId).subscribe((result) => {
          if (result.succeeded) {
            this.appointment = result.value;
            this.initFormWithValue();
          } else {
            this.initForm();
          }
        });
      } else {
        this.initForm();
      }
    });
  }

  async getLookups() {
    const appointmentTypes = await this.dhhLookupService.getAppointmentTypes().toPromise();
    if (!this.canAddSoundBooth) {
      appointmentTypes.value = appointmentTypes.value.filter((t) => t.label !== 'Sound Booth');
      this.appointmentTypeOptions = appointmentTypes.value.map((t) => new KeyValuePair(t.id, t.label));
    } else {
      this.appointmentTypeOptions = appointmentTypes.value.map((t) => new KeyValuePair(t.id, t.label));
    }

    const soundBoothLocations = await this.dhhLookupService.getSoundBoothLocations().toPromise();
    this.soundBoothLocationOptions = soundBoothLocations.value.map((a) => new KeyValuePair(a.id, a.label));

    const audiologists = await this.dhhLookupService.getAudiologists().toPromise();
    this.audiologistOptions = audiologists.value.map((a) => new KeyValuePair(a.id, a.label));

    const audiometrists = await this.dhhLookupService.getAudiometrists().toPromise();
    this.audiometristOptions = audiometrists.value.map((a) => new KeyValuePair(a.id, a.label));

    const removeChildren = (lookup) => {
      return {
        childRequired: lookup.childRequired,
        id: lookup.id,
        isDeleted: lookup.isDeleted,
        label: lookup.label,
        lookupName: lookup.lookupName,
        parentId: lookup.parentId,
      };
    };

    const locations = await this.dhhLookupService.getLocations().toPromise();
    const aeas = locations ?? [];
    const districts = aeas.flatMap((x) => x.children);
    this.districtOptions = this.getHierarchicalLookup(districts);

    const buildingsLookup = districts.flatMap((x) => x.children);
    this.buildings = buildingsLookup.map((x) => removeChildren(x)).sort((a, b) => a.label.localeCompare(b.label)) || [];
  }

  private getHierarchicalLookup(lookup, parentId?) {
    return lookup.filter((x) => (parentId && x.parentId === parentId) || !parentId).map((x) => new KeyValuePair(x.id, x.label));
  }

  initForm() {
    this.formGroup = this.fb.group({
      id: [null],
      appointmentTypeId: [null, [Validators.required]],
      districtId: [null, [Validators.required]],
      buildingId: [],
      screening: this.fb.group({
        startDate: [null, [Validators.required]],
        endDate: [null, [Validators.required]],
        audiologistIds: [null],
        audiometristIds: [null],
      }),
      scheduleRecheck: [null],
      recheck: this.fb.group({
        startDate: [null, [Validators.required]],
        endDate: [null, [Validators.required]],
        audiologistIds: [null, [Validators.required]],
        audiometristIds: [null],
      }),
      soundbooth: this.fb.group({
        soundBoothLocationId: [null],
        date: [null, [Validators.required]],
        startTime: [null, [Validators.required]],
        endTime: [null, [Validators.required]],
        audiologistIds: [null, [Validators.required]],
      }),
      notes: [null],
    });

    this.formGroup.get('screening').addValidators(this.hasAudiometristOrAudiologistValidator);
    this.formGroup.get('screening').updateValueAndValidity();

    this.setFormSubscription();
  }

  initFormWithValue() {
    this.formGroup = this.fb.group({
      id: [this.appointment.id],
      appointmentTypeId: [{ value: this.appointment.appointmentTypeId, disabled: true }],
      districtId: [{ value: this.appointment.districtId, disabled: true }],
      buildingId: [{ value: this.appointment.buildingId, disabled: true }],
      screening: this.fb.group({
        startDate: [{ value: this.appointment.startDate, disabled: true }],
        endDate: [{ value: this.appointment.endDate, disabled: true }],
        audiologistIds: [this.appointment.audiologistIds],
        audiometristIds: [this.appointment.audiometristIds],
      }),
      scheduleRecheck: [null],
      recheck: this.fb.group({
        startDate: [{ value: this.appointment.startDate, disabled: true }],
        endDate: [{ value: this.appointment.endDate, disabled: true }],
        audiologistIds: [this.appointment.audiologistIds],
        audiometristIds: [this.appointment.audiometristIds],
      }),
      soundbooth: this.fb.group({
        soundBoothLocationId: [{ value: this.appointment.soundBoothLocationId, disabled: true }],
        date: [{ value: this.appointment.startDate, disabled: true }],
        startTime: [this.appointment.startTime],
        endTime: [this.appointment.endTime],
        audiologistIds: [this.appointment.audiologistIds],
      }),
      notes: [{ value: this.appointment.notes, disabled: true }],
    });
    this.formGroup.get('screening').addValidators(this.hasAudiometristOrAudiologistValidator);
    this.formGroup.get('screening').updateValueAndValidity();

    this.updateRequiredValidators(this.appointment.appointmentType.label);

    this.filterBuildings();

    setTimeout(() => {
      this.subscriptions.add(
        this.formGroup.get('districtId').valueChanges.subscribe((districtId) => {
          this.formGroup.get('buildingId').reset();
          this.filterBuildings(districtId);
        })
      );
    }, 100);
  }

  setFormSubscription() {
    this.formGroup.controls.appointmentTypeId.valueChanges.subscribe((value) => {
      this.formGroup.controls.scheduleRecheck.setValue(false);
      if (value) {
        const selectedType = this.appointmentTypeOptions.find((t) => t.key === value)?.value;
        this.updateRequiredValidators(selectedType);
      } else {
        this.updateRequiredValidators(null);
      }
    });

    this.formGroup.controls.scheduleRecheck.valueChanges.subscribe((recheckChecked) => {
      if (recheckChecked) {
        this.recheckEnabled = true;
        this.setRequiredValidator(this.getRecheckFormGroup.controls.startDate);
        this.setRequiredValidator(this.getRecheckFormGroup.controls.endDate);
        this.setRequiredValidator(this.getRecheckFormGroup.controls.audiologistIds);
      } else {
        this.removeValidators(this.getRecheckFormGroup);
        this.recheckEnabled = false;
      }
    });

    this.subscriptions.add(
      this.formGroup.get('districtId').valueChanges.subscribe((districtId) => {
        this.formGroup.get('buildingId').reset();
        this.filterBuildings(districtId);
      })
    );
  }

  updateRequiredValidators(eventType: string) {
    if (eventType === 'Screening') {
      this.screeningEnabled = true;
      this.setRequiredValidator(this.getScreeningFormGroup.controls.startDate);
      this.setRequiredValidator(this.getScreeningFormGroup.controls.endDate);
      this.setRequiredValidator(this.formGroup.controls.districtId);

      if (this.formGroup.controls.scheduleRecheck.value) {
        this.recheckEnabled = true;
        this.setRequiredValidator(this.getRecheckFormGroup.controls.startDate);
        this.setRequiredValidator(this.getRecheckFormGroup.controls.endDate);
        this.setRequiredValidator(this.getRecheckFormGroup.controls.audiologistIds);
      } else {
        this.recheckEnabled = false;
        this.removeValidators(this.getRecheckFormGroup);
      }

      this.soundboothEnabled = false;
      this.removeValidators(this.getSoundboothFormGroup);
    } else if (eventType === 'Recheck') {
      this.recheckEnabled = true;
      this.setRequiredValidator(this.getRecheckFormGroup.controls.startDate);
      this.setRequiredValidator(this.getRecheckFormGroup.controls.endDate);
      this.setRequiredValidator(this.getRecheckFormGroup.controls.audiologistIds);
      this.setRequiredValidator(this.formGroup.controls.districtId);

      this.screeningEnabled = false;
      this.removeValidators(this.getScreeningFormGroup);
      this.soundboothEnabled = false;
      this.removeValidators(this.getSoundboothFormGroup);
    } else if (eventType === 'Sound Booth') {
      this.soundboothEnabled = true;
      this.setRequiredValidator(this.getSoundboothFormGroup.controls.soundBoothLocationId);
      this.setRequiredValidator(this.getSoundboothFormGroup.controls.date);
      this.setRequiredValidator(this.getSoundboothFormGroup.controls.startTime);
      this.setRequiredValidator(this.getSoundboothFormGroup.controls.endTime);
      this.setRequiredValidator(this.getSoundboothFormGroup.controls.audiologistIds);

      this.screeningEnabled = false;
      this.removeValidators(this.getScreeningFormGroup);
      this.recheckEnabled = false;
      this.removeValidators(this.getRecheckFormGroup);
      this.removeValidator(this.formGroup.controls.districtId);
    } else {
      this.screeningEnabled = false;
      this.recheckEnabled = false;
      this.soundboothEnabled = false;

      this.removeValidators(this.getScreeningFormGroup);
      this.removeValidators(this.getRecheckFormGroup);
      this.removeValidators(this.getSoundboothFormGroup);
    }
  }

  private removeValidators(form: FormGroup) {
    for (const key in form.controls) {
      form.get(key).clearValidators();
      form.get(key).setValue(null);
      form.get(key).updateValueAndValidity();
    }
  }

  private removeValidator(control: AbstractControl) {
    control.clearValidators();
    control.setValue(null);
    control.updateValueAndValidity();
  }

  private setRequiredValidator(control: AbstractControl) {
    control.setValidators(Validators.required);
    control.updateValueAndValidity();
  }

  filterBuildings(districtId?: string) {
    if (districtId) {
      this.buildingOptions = this.getHierarchicalLookup(this.buildings, districtId);
    } else {
      this.buildingOptions = this.getHierarchicalLookup(this.buildings);
    }
  }

  onCancel() {
    this.closeWindow();
  }

  onSave() {
    if (this.appointment) {
      this.updateAppointment();
    } else {
      this.addAppointments();
    }
  }

  closeWindow() {
    window.close();
  }

  addAppointments() {
    const appointments: DhhAppointmentDto[] = [];
    if (this.screeningEnabled) {
      const audiologistIds = this.getScreeningFormGroup.controls.audiologistIds.value;
      const audiometristIds = this.getScreeningFormGroup.controls.audiometristIds.value;
      appointments.push({
        appointmentTypeId: this.formGroup.controls.appointmentTypeId.value,
        districtId: this.formGroup.controls.districtId.value,
        buildingId: this.formGroup.controls.buildingId.value,
        startDate: this.getScreeningFormGroup.controls.startDate.value,
        endDate: this.getScreeningFormGroup.controls.endDate.value,
        audiologistIds: audiologistIds,
        audiometristIds: audiometristIds,
        notes: this.formGroup.controls.notes.value,
      } as DhhAppointmentDto);

      if ((audiologistIds === null || audiologistIds.length === 0) && (audiometristIds === null || audiometristIds.length === 0)) {
        this.dhhService.handleError('You must choose either Audiologist or Audiometrist', {
          message: 'For Screening appointment you must choose either Audiologist or Audiometrist.',
        });
        return;
      }
    }

    if (this.recheckEnabled) {
      appointments.push({
        appointmentTypeId: this.appointmentTypeOptions.find((t) => t.value === 'Recheck').key,
        districtId: this.formGroup.controls.districtId.value,
        buildingId: this.formGroup.controls.buildingId.value,
        startDate: this.getRecheckFormGroup.controls.startDate.value,
        endDate: this.getRecheckFormGroup.controls.endDate.value,
        audiologistIds: this.getRecheckFormGroup.controls.audiologistIds.value,
        audiometristIds: this.getRecheckFormGroup.controls.audiometristIds.value,
        notes: this.formGroup.controls.notes.value,
      } as DhhAppointmentDto);
    }

    if (this.soundboothEnabled) {
      appointments.push({
        appointmentTypeId: this.formGroup.controls.appointmentTypeId.value,
        soundBoothLocationId: this.getSoundboothFormGroup.controls.soundBoothLocationId.value,
        startDate: this.getSoundboothFormGroup.controls.date.value,
        startTime: this.getSoundboothFormGroup.controls.startTime.value,
        endTime: this.getSoundboothFormGroup.controls.endTime.value,
        audiologistIds: this.getSoundboothFormGroup.controls.audiologistIds.value,
      } as DhhAppointmentDto);
    }

    this.isSaving = true;
    this.dhhService.addAppointments(appointments).subscribe(
      (result) => {
        if (result.succeeded) {
          this.closeWindow();
        } else {
          this.dhhService.handleError('Failed to Save Appointment', result);
        }
      },
      (error) => {
        this.dhhService.handleError('Failed to save appointments', error);
      },
      () => {
        this.isSaving = false;
      }
    );
  }

  updateAppointment() {
    let audiologistIds = [];
    let audiometristIds = [];

    switch (this.appointment.appointmentType.label) {
      case 'Screening': {
        audiologistIds = this.getScreeningFormGroup.controls.audiologistIds.value;
        audiometristIds = this.getScreeningFormGroup.controls.audiometristIds.value;
        break;
      }
      case 'Recheck': {
        audiologistIds = this.getRecheckFormGroup.controls.audiologistIds.value;
        audiometristIds = this.getRecheckFormGroup.controls.audiometristIds.value;
        break;
      }
      case 'Sound Booth': {
        audiologistIds = this.getSoundboothFormGroup.controls.audiologistIds.value;
        break;
      }
      default: {
        break;
      }
    }

    if (this.appointment.appointmentType.label === 'Screening' && audiologistIds?.length === 0 && audiometristIds?.length === 0) {
      this.dhhService.handleError('You must choose either Audiologist or Audiometrist', {
        message: 'For Screening appointment you must choose either Audiologist or Audiometrist.',
      });
      return;
    }

    this.isSaving = true;
    const appointment = {
      id: this.appointment.id,
      appointmentTypeId: this.appointment.appointmentTypeId,
      soundBoothLocationId: this.appointment.soundBoothLocationId,
      startDate: this.appointment.startDate,
      endDate: this.appointment.endDate,
      districtId: this.appointment.districtId,
      buildingId: this.appointment.buildingId,
      startTime: this.getSoundboothFormGroup.controls.startTime.value,
      endTime: this.getSoundboothFormGroup.controls.endTime.value,
      audiologistIds: audiologistIds,
      audiometristIds: audiometristIds,
      notes: this.appointment.notes,
    } as DhhAppointmentDto;

    this.dhhService.updateAppointment(appointment).subscribe(
      (result) => {
        if (result.succeeded) {
          this.closeWindow();
        } else {
          this.dhhService.handleError('Failed to Update Appointment', result);
        }
      },
      (error) => {
        this.dhhService.handleError('There was an error', error);
      },
      () => {
        this.isSaving = false;
      }
    );
  }

  private hasAudiometristOrAudiologistValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    if (
      this.formGroup.get('appointmentTypeId').value === DhhAppointmentTypeIds.Screening &&
      !control.get('audiometristIds').value?.length &&
      !control.get('audiologistIds').value?.length
    )
      return { needsAssignment: true };
    return null;
  };

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }
}
