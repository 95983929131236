import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { shortDateFormat } from '../../../../shared/dateTimeHelpers';
import { KeyValuePair } from '../../../../shared/models/key-value-pair';
import { PaginatedList } from '../../../../shared/models/paginated-list';
import { DhhAddLearnerFromSifDto, DhhLearnerSearchResultDto } from '../../../models/DhhDtos';
import { DhhService } from '../../../services/dhh.service';
import { SpinnerService } from '../../../../shared/services/spinner/spinner.service';
import { DhhRoutingService } from '../../../services/dhh-routing.service';

@Component({
  selector: 'app-child-search-learner-list',
  templateUrl: './child-search-learner-list.component.html',
  styleUrls: ['./child-search-learner-list.component.scss'],
})
export class ChildSearchLearnerListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() learners: PaginatedList<DhhLearnerSearchResultDto>;

  @Output() tablePaginationEvent = new EventEmitter<any>();

  @ViewChild('learnersTable') learnersTable: ElementRef;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  pageSizeOptions: KeyValuePair[] = [new KeyValuePair(5, 5), new KeyValuePair(10, 10), new KeyValuePair(20, 20), new KeyValuePair(50, 50)];
  displayedColumns = [
    'selectMatch',
    'fullName',
    'dateOfBirth',
    'grade',
    'status',
    'audiologistName',
    'tdhhName',
    'attendingAea',
    'attendingDistrict',
  ];
  dataSource = new MatTableDataSource<DhhLearnerSearchResultDto>();

  orderBy = 'fullName';
  orderByDescending = false;
  currentPage = 0;
  pageSize = 20;
  totalRows = 0;
  subscriptions = new Subscription();
  shortDateFormat = shortDateFormat;
  initialized = false;

  get currentPageInfo() {
    return {
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      length: this.totalRows,
    } as PageEvent;
  }

  constructor(
    private readonly dhhService: DhhService,
    private readonly spinnerService: SpinnerService,
    private readonly dhhRoutingService: DhhRoutingService
  ) {}

  ngOnInit(): void {
    this.initialized = true;

    this.initDataSource(true);
  }

  ngOnChanges() {
    if (this.initialized) {
      this.initDataSource();
    }
  }

  private initDataSource(initialLoad = false) {
    if (this.learners?.items) {
      this.totalRows = this.learners.totalCount;
      this.dataSource = new MatTableDataSource(this.learners?.items);
    } else {
      this.totalRows = 0;
      this.dataSource = new MatTableDataSource([]);
    }
  }

  onSortChange(event: any) {
    this.orderBy = event.active;
    this.orderByDescending = event.direction === 'desc' ? true : false;
    this.emitChange();
  }

  async onPageChanged(event: PageEvent): Promise<void> {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.scrollToView(this.learnersTable);
    this.emitChange();
  }

  emitChange() {
    this.tablePaginationEvent.emit({
      pageNumber: this.currentPage,
      pageSize: this.pageSize,
      orderBy: this.orderBy,
      orderByDescending: this.orderByDescending,
    });
  }

  private scrollToView(element: ElementRef): void {
    element?.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  selectLearner(element) {
    if (element.isSif) {
      setTimeout(() => this.spinnerService.incrementLoading());
      const dto = { refId: element.id, zoneId: element.zoneId } as DhhAddLearnerFromSifDto;
      this.dhhService.addLearnerFromSif(dto).subscribe(
        (res) => {
          if (res.succeeded) {
            this.dhhRoutingService.goToLearnerHearingDashboard(res.value);
          } else {
            this.dhhService.handleError('Add Learner failed', res);
          }
          setTimeout(() => this.spinnerService.decrementLoading());
        },
        (error) => {
          this.dhhService.handleError('Add Learner failed', error);
          setTimeout(() => this.spinnerService.decrementLoading());
        }
      );
    } else {
      this.dhhRoutingService.goToLearnerHearingDashboard(element.id);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
