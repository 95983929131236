import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { ViewMoreModalData } from '../../../shared/modals/view-more-modal/view-more-modal.component';
import { openViewMore } from '../../../shared/tableHelpers';
import { DhhLearnerDto, DhhLearnerSearchResultDto } from '../../models/DhhDtos';
import { DhhService } from '../../services/dhh.service';

@Component({
  selector: 'app-dhh-pending-learners',
  templateUrl: './dhh-pending-learners.component.html',
  styleUrls: ['./dhh-pending-learners.component.scss'],
})
export class DhhPendingLearnersComponent implements OnInit {
  pendingLearners: DhhLearnerSearchResultDto[] = [];
  displayedColumns = ['action', 'fullName', 'dateOfBirth', 'attendingAea', 'attendingDistrict', 'building', 'grade', 'classroom'];
  pageTitle = 'Learners in Pending State';

  constructor(
    private readonly dhhService: DhhService,
    public readonly authService: AuthService,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getPendingLearners();
  }

  getPendingLearners() {
    this.dhhService.pendingLearners().subscribe(
      (result) => {
        if (result.succeeded) {
          this.pendingLearners = result.value;
        } else {
          this.dhhService.handleError('Failed to load pending learners', result);
        }
      },
      (error) => {
        this.dhhService.handleError('Error while loading pending learners', error);
      }
    );
  }

  onDelete(learner: DhhLearnerSearchResultDto) {
    this.dhhService.deleteLearner(learner.id).subscribe(
      (result) => {
        if (result.succeeded) {
          this.pendingLearners = this.pendingLearners.filter((l) => l.id !== learner.id);
        } else {
          this.dhhService.handleError('Failed to delete pending learner record', result);
        }
      },
      (error) => {
        this.dhhService.handleError('Error while deleting learner', error);
      }
    );
  }

  onUpdate(learner: DhhLearnerSearchResultDto) {
    this.router.navigate(['dhh', 'pending-learner', learner.id]);
  }

  onViewMore(learner: DhhLearnerSearchResultDto) {
    this.dhhService.getLearner(learner.id).subscribe(
      (result) => {
        if (result.succeeded) {
          this.viewMore(result.value);
        } else {
          this.dhhService.handleError('Failed to load learner data', result);
        }
      },
      (error) => {
        this.dhhService.handleError('There was an error while retrieving learner data', error);
      }
    );
  }

  viewMore(learner: DhhLearnerDto) {
    const familyMembers: ViewMoreModalData[] = [];

    learner.familyMembers?.forEach((f, index) => {
      familyMembers.push({
        name: `Family Member ${index + 1}`,
        value: [
          { name: 'Family Name', value: f.fullName },
          { name: 'Email', value: f.email },
          { name: 'Home Phone Number', value: f.homePhone },
          { name: 'Work Phone Number', value: f.workPhone },
          { name: 'Cell Phone Number', value: f.cellPhone },
          { name: 'Address', value: `${f.streetAddress ? f.streetAddress + ', ' : ''} ${f.city} ${f.state} ${f.zipCode}` },
        ],
        nested: true,
      });
    });

    const modalData: ViewMoreModalData[] = [
      {
        name: 'Learner Name',
        value: `${learner.firstName} ${learner.lastName}`,
      },
      {
        name: 'Date of Birth',
        value: this.getDateOfBirthString(learner.dateOfBirth),
      },
      {
        name: 'Grade',
        value: learner.gradeDescription,
      },
      {
        name: 'State Id',
        value: learner.stateAssignedId,
      },
      {
        name: '',
        value: familyMembers,
        nested: true,
      },
    ];

    openViewMore(this.dialog, modalData);
  }

  private getDateOfBirthString(dateOfBirth: string) {
    if (dateOfBirth) {
      const date = new Date(dateOfBirth);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return month + '/' + day + '/' + year;
    }
    return '';
  }
}
